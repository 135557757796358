import { useMedia } from 'react-use';

const useMediaCustom = () => {
  const isMobileS = useMedia('(max-width: 320px)');
  const isMobileM = useMedia('(max-width: 375px)');
  const isMobileL = useMedia('(max-width: 425px)');
  const isMobileXL = useMedia('(max-width: 640px)');
  const isTablet = useMedia('(max-width: 768px)');
  const isLaptop = useMedia('(max-width: 1024px)');
  const isLaptopL = useMedia('(max-width: 1440px)');

  return {
    isMobileS,
    isMobileM,
    isMobileL,
    isMobileXL,
    isTablet,
    isLaptop,
    isLaptopL
  };
};

export default useMediaCustom;
