import type { AxiosInstance } from 'axios';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { server } from 'commons/constants/config';
import sanitizeHtml from 'sanitize-html';
import invariant from 'tiny-invariant';
import { z } from 'zod';
import { getLocalStorage } from 'utils/localStorage';
import {
  CONTENT_TOO_LARGE,
  I18NEXT_LANG,
  UNKNOWN_ERROR
} from 'commons/constants/variable';
import { IAuthService } from 'service/auth-service/AuthService';
import { storage } from 'utils/storage';

type Logger = {
  captureException: (error: Error) => void;
};

type Config = {
  authService: IAuthService;
  isShare?: boolean;
  logger?: Logger;
};

interface ErrorResponse {
  statusCode: string;
  statusMessage: string;
  message: string;
  errors: string[];
}

interface RequestConfig extends AxiosRequestConfig {
  responseSchema?: z.ZodSchema;
  bypassSanitize?: boolean;
  isBlobFile?: boolean;
}

export const signOut = (): void => {
  storage.clear();
  if (window.location.pathname !== '/p2p/login') {
    window.location.replace('/p2p/login');
  }
};

const handleError = (error?: AxiosError): void => {
  /** Will be iterate for error case */
  if (error?.request?.status === 401) {
    signOut();
    throw new Error((error?.response?.data as ErrorResponse)?.statusMessage);
  } else if (error?.request?.status === 400) {
    const errorResponse = error?.response?.data as ErrorResponse;
    const errorMessage =
      Array.isArray(errorResponse?.errors) && errorResponse.errors.length > 0
        ? JSON.stringify(errorResponse.errors)
        : errorResponse?.message;
    throw new Error(errorMessage);
  } else if (error?.request?.status === CONTENT_TOO_LARGE) {
    throw new Error('413 File to large');
  } else if (error?.request?.status === UNKNOWN_ERROR) {
    throw new Error('502 Unknown error occurred');
  } else {
    throw new Error((error?.response?.data as ErrorResponse)?.statusMessage);
  }
};

const dev = window.__RUNTIME_CONFIG__.NODE_ENV === 'development';

const shareApi = dev ? `/share` : server.share;
const proxyUrl = dev ? `/mobile` : `${server.api}`;

export abstract class BaseHttpClient {
  protected client: AxiosInstance;

  private baseUrl: string;

  public authService: IAuthService;

  constructor(config: Config) {
    this.client = axios.create({});
    this.baseUrl = config.isShare ? shareApi : proxyUrl;
    this.authService = config.authService;
    this.initializeRequestInterceptor();
    this.initializeResponseInterceptor();
  }

  private initializeRequestInterceptor() {
    this.client.interceptors.request.use(
      // @ts-ignore
      async (config) => {
        const axiosConfig = config as RequestConfig;
        const { token } = this.authService.getUser() ?? '';
        const lang = getLocalStorage(I18NEXT_LANG) ?? 'id';
        const sanitizedUrl =
          axiosConfig.url && axiosConfig?.bypassSanitize
            ? JSON.parse(JSON.stringify(axiosConfig.url))
            : JSON.parse(sanitizeHtml(JSON.stringify(axiosConfig.url)));
        const sanitizedParams =
          axiosConfig.params && !axiosConfig?.bypassSanitize
            ? JSON.parse(sanitizeHtml(JSON.stringify(axiosConfig.params)))
            : axiosConfig.params;
        const sanitizedData =
          axiosConfig.data &&
          !(axiosConfig.data instanceof FormData) &&
          !axiosConfig?.bypassSanitize
            ? JSON.parse(
                sanitizeHtml(JSON.stringify(axiosConfig.data))
                  .replace('&amp;', '&')
                  .replace('&lt;', '<')
                  .replace('&gt;', '>')
                  .replace('&quot;', '"')
                  .replace('&apos;', "'")
              )
            : axiosConfig.data;

        const { isBlobFile } = axiosConfig;

        if (isBlobFile) {
          axiosConfig.responseType = 'blob';
        }

        return {
          ...axiosConfig,
          params: sanitizedParams,
          data: sanitizedData,
          url: sanitizedUrl,
          withCredentials: true,
          headers: {
            ...axiosConfig.headers,
            'Accept-Language': lang,
            Authorization: token ? `Bearer ${token}` : ''
          }
        };
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }

  private initializeResponseInterceptor() {
    this.client.interceptors.response.use(
      (response) => {
        if (response?.status >= 200 || response?.status < 400) {
          if (response?.data?.data || response?.data?.data >= 0) {
            return response?.data?.data;
          }
          return response.data;
        }
        return response.data;
      },
      (error: AxiosError) => {
        if (error?.request) {
          handleError(error);
        }
        return Promise.reject(error);
      }
    );
  }

  private static isValidUrl(baseUrl: string): boolean {
    if (baseUrl) {
      return true;
    }
    return false;
  }

  private static createUrl(baseUrl: string, path: string): string {
    invariant(BaseHttpClient.isValidUrl(baseUrl), 'The base url is not set');
    invariant(BaseHttpClient.isValidUrl(path), 'The path url is not set');
    return baseUrl + path;
  }

  protected async get<T>(path: string, config?: RequestConfig): Promise<T> {
    const url = BaseHttpClient.createUrl(this.baseUrl, path);
    invariant(url, 'The url is not valid');
    const response = (await this.client.get(url, config)) as Promise<T>;

    if (config?.responseSchema) {
      try {
        return config.responseSchema.parse(response);
      } catch (error) {
        return response;
      }
    }

    return response;
  }

  protected post<T>(
    path: string,
    data?: unknown,
    config?: RequestConfig
  ): Promise<T> {
    const url = BaseHttpClient.createUrl(this.baseUrl, path);
    invariant(url, 'The url is not valid');

    return this.client.post(url, data, config);
  }

  protected put<T>(
    path: string,
    data?: unknown,
    config?: RequestConfig
  ): Promise<T> {
    const url = BaseHttpClient.createUrl(this.baseUrl, path);
    invariant(url, 'The url is not valid');

    return this.client.put(url, data, config);
  }

  protected patch<T>(
    path: string,
    data?: unknown,
    config?: RequestConfig
  ): Promise<T> {
    const url = BaseHttpClient.createUrl(this.baseUrl, path);
    invariant(url, 'The url is not valid');

    return this.client.patch(url, data, config);
  }

  protected delete<T>(path: string, config?: AxiosRequestConfig): Promise<T> {
    const url = BaseHttpClient.createUrl(this.baseUrl, path);
    invariant(url, 'The url is not valid');

    return this.client.delete(url, config);
  }
}
