import Cookies from 'js-cookie';
import dayjs from 'dayjs';

export const setCookie = (key: string, value: string) => {
  const inThirtyMin = dayjs().add(30, 'm').toDate();
  Cookies.set(key, value, {
    expires: inThirtyMin,
    path: '/'
  });
};

export const removeCookie = (key: string) => {
  Cookies.remove(key, {
    path: '/'
  });
};

export const getCookie = (key: string): string | undefined => {
  return Cookies.get(key);
};
