import { z } from 'zod';

export type SimulateParams = {
  applicationId: string;
  chipInAmount: number;
};

export const simulateCalculatorSchema = z.object({
  ujrohBeforeTax: z.number(),
  serviceFeeAmount: z.number(),
  serviceFeeTaxAmount: z.number(),
  pphTaxAmount: z.number(),
  rewardAmount: z.number(),
  finalUjrohAmountEstimation: z.number(),
  estTotalRepaymentAmount: z.number()
});

export type SimulationUjroh = z.infer<typeof simulateCalculatorSchema>;
