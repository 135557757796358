/* globals FetchsMethod */
/* [method, path, URL(false/undefined) / URL_SHARE(true)] */
export type Endpoint = [FetchsMethod, string, boolean?];

/* URL used env.URL or runtime-env.js */
/* Application image */
export const APP_IMAGE_GET: Endpoint = [
  'get',
  '/p2p/api/funder/applicationImage/:idCampaign'
];
/* Languange */
export const I18N_VERSION_GET: Endpoint = [
  'get',
  '/p2p/api/funder/i18n/versions'
];
export const I18N_MESSAGE_GET: Endpoint = [
  'get',
  '/p2p/api/funder/i18n/messages/:lang'
];
/* Auth */
export const REFRESH_TOKEN_GET: Endpoint = [
  'get',
  '/p2p/api/funder/refresh-token'
];
export const RESET_PASSWORD_POST: Endpoint = [
  'post',
  '/p2p/api/funder/reset-password'
];
export const PASSWORD_POST: Endpoint = [
  'post',
  '/p2p/api/funder/submit-reset-password'
]; // /submit-reset-password?expiry
/* ============ */
/* Dashboard */
export const ALAMI_INFO_GET: Endpoint = [
  'get',
  '/p2p/api/funder/v2/alami-info'
];
export const APPROVE_INVESTMENT_POST: Endpoint = [
  'post',
  '/p2p/api/funder/approve-investment'
];
export const APPROVE_ADDING_INVESTMENT_POST: Endpoint = [
  'post',
  '/p2p/api/funder/approve-adding-investment'
];
export const APPROVE_INSTITUTIONAL_INVESTMENT_POST: Endpoint = [
  'post',
  '/p2p/api/funder/approve-institutional-investment'
];
export const APPROVE_PENDING_INVESTMENT_POST: Endpoint = [
  'post',
  '/p2p/api/funder/approve-pending-investment'
];
export const APPROVE_PENDING_INVESTMENT_INSTITUTIONAL_POST: Endpoint = [
  'post',
  '/p2p/api/funder/v1/institution/approve-pending-investment'
];
export const BOOK_INVESTMENT_POST: Endpoint = [
  'post',
  '/p2p/api/funder/1.3/book-investment'
];
export const CAMPAIGN_LIST_GET: Endpoint = [
  'get',
  '/p2p/api/funder/1.4/applications'
];
export const CAMPAIGN_DETAIL_GET: Endpoint = [
  'get',
  '/p2p/api/funder/1.5/application/:idCampaign'
];
export const CAMPAIGN_DETAIL_STATUS_GET: Endpoint = [
  'get',
  '/p2p/api/funder/1.5/application/:idCampaign/status'
];
export const DIGISIGN_ACTIVATION_AKAD_03_POST: Endpoint = [
  'post',
  '/p2p/api/funder/digisign/callback/activation'
];
export const DIGISIGN_ACTIVATION_AKAD_PAYUNG_POST: Endpoint = [
  'post',
  '/p2p/api/funder/digisign/1.4/callback/activation'
];
export const DIGISIGN_DOCUMENT_AKAD_PAYUNG_GET: Endpoint = [
  'get',
  '/p2p/api/funder/digisign/1.4/document'
];
export const DIGISIGN_DOCUMENT_CALLBACK_AKAD_PAYUNG_GET: Endpoint = [
  'get',
  '/p2p/api/funder/digisign/1.4/callback/document'
];
export const DIGISIGN_DOCUMENT_AKAD_03_GET: Endpoint = [
  'get',
  '/p2p/api/funder/digisign/1.2/document/:applicationLenderId'
];
export const DIGISIGN_DOCUMENT_CALLBACK_AKAD_03_GET: Endpoint = [
  'get',
  '/p2p/api/funder/digisign/1.2/callback/document/:applicationLenderId'
];
export const DIGISIGN_REGISTER_AKAD_03_POST: Endpoint = [
  'post',
  '/p2p/api/funder/digisign/register'
];
export const DIGISIGN_REGISTER_AKAD_PAYUNG_POST: Endpoint = [
  'post',
  '/p2p/api/funder/1.4/digisign/register'
];
export const DOCUMENT_LIST_GET: Endpoint = ['get', '/p2p/api/funder/documents'];
export const DOCUMENT_PDF_GET: Endpoint = [
  'get',
  '/p2p/api/funder/documents/:docUrl'
];
export const DOCUMENT_PDF_SEND_POST: Endpoint = [
  'post',
  '/p2p/api/funder/documents/send/:docUrl'
];
export const GENERATE_FACTSHEET_GET: Endpoint = [
  'get',
  '/p2p/api/funder/generate-factsheet/:idFactsheet'
];
export const INVESTMENT_CALENDAR: Endpoint = [
  'get',
  '/p2p/api/funder/investment-calender'
];
export const LIST_MARITAL_STATUS_GET: Endpoint = [
  'get',
  '/p2p/api/funder/profile/marital-status'
];
export const LIST_EDUCATION_LEVEL_GET: Endpoint = [
  'get',
  '/p2p/api/funder/profile/education-level'
];
export const LIST_GENDER_GET: Endpoint = [
  'get',
  '/p2p/api/funder/profile/gender'
];
export const LIST_TITLE_GET: Endpoint = [
  'get',
  '/p2p/api/funder/profile/customer-title'
];
export const LIST_PROVINCE_GET: Endpoint = [
  'get',
  '/p2p/api/funder/location/level/1'
];
export const LIST_CITY_GET: Endpoint = [
  'get',
  '/p2p/api/funder/location/level-parent/2/:locationId'
];
export const LIST_SUB_VILLAGE_GET: Endpoint = [
  'get',
  '/p2p/api/funder/location/level-parent/3/:locationId'
];
export const LIST_VILLAGE_GET: Endpoint = [
  'get',
  '/p2p/api/funder/location/level-parent/4/:locationId'
];
export const LIST_POSTAL_CODE_GET: Endpoint = [
  'get',
  '/p2p/api/funder/location/post-code/:locationId'
];
export const LIST_JOB_GET: Endpoint = ['get', '/p2p/api/funder/profile/jobs'];
export const LIST_INDUSTRY_GET: Endpoint = [
  'get',
  '/p2p/api/funder/profile/industry'
];
export const LIST_COUNTRY_GET: Endpoint = [
  'get',
  '/p2p/api/funder/master/v2/profile/countries'
];
export const LIST_MONTHLY_INCOME_GET: Endpoint = [
  'get',
  '/p2p/api/funder/profile/monthly-income'
];
export const LIST_SOURCE_FUND_GET: Endpoint = [
  'get',
  '/p2p/api/funder/profile/sumber-dana'
];
export const LIST_CONTACT_RELATION_GET: Endpoint = [
  'get',
  '/p2p/api/funder/profile/contact-relation'
];
export const LIST_POSITION_GET: Endpoint = [
  'get',
  '/p2p/api/funder/master/v2/occupation/positions'
];
export const LIST_JOB_MASTER_GET: Endpoint = [
  'get',
  '/p2p/api/funder/master/v2/occupation/types'
];
export const OTP_VALIDATE_POST: Endpoint = [
  'post',
  '/p2p/api/funder/otp/validate'
];
export const PORTOFOLIO_ACTIVE_GET: Endpoint = [
  'get',
  '/p2p/api/funder/portofolio/investments/active'
];
export const PORTOFOLIO_CAMPAIGN_TIMELINE_GET: Endpoint = [
  'get',
  '/p2p/api/funder/v2.1/portofolio/:idCampaign/timeline'
];
export const PORTOFOLIO_FINISHED_GET: Endpoint = [
  'get',
  '/p2p/api/funder/portofolio/history-funding'
];
export const PORTOFOLIO_INVESTMENT_PENDING_GET: Endpoint = [
  'get',
  '/p2p/api/funder/portofolio/investments/pending'
];
export const PORTOFOLIO_LIST_INVESTMENT_GET: Endpoint = [
  'get',
  '/p2p/api/funder/portofolio/list-investment'
];
export const PORTOFOLIO_ONGOING_GET: Endpoint = [
  'get',
  '/p2p/api/funder/portofolio/ongoing-funding'
];
export const PORTOFOLIO_SUMMARY_GET: Endpoint = [
  'get',
  '/p2p/api/funder/1.2/summary/portfolio'
];
export const PROFILE_STEP_1_GET: Endpoint = [
  'get',
  '/p2p/api/funder/profile/step-1/1.3/'
];
export const PROFILE_STEP_1_POST: Endpoint = [
  'post',
  '/p2p/api/funder/profile/step-1/1.3/'
];
export const PROFILE_STEP_2_GET: Endpoint = [
  'get',
  '/p2p/api/funder/profile/step-2/'
];
export const PROFILE_STEP_3_GET: Endpoint = [
  'get',
  '/p2p/api/funder/profile/step-3/1.2/'
];
export const PROFILE_EMAIL_VERIFY_POST: Endpoint = [
  'post',
  '/p2p/api/funder/profile/email/verify'
];
export const RDL_HISTORY_TRANSACTION_GET: Endpoint = [
  'get',
  '/p2p/api/funder/rdl/account-balance/history/'
];
export const RDL_WITHDRAW_ACCOUNT_GET: Endpoint = [
  'get',
  '/p2p/api/funder/rdl/withdraw-account'
];
export const RDL_WITHDRAWAL_LIST_GET: Endpoint = [
  'get',
  '/p2p/api/funder/rdl/withdrawal-list/'
];
export const RELAMI_CODE_GET: Endpoint = ['get', '/p2p/api/funder/relami-code'];
export const TAX_REPORT_GET: Endpoint = [
  'get',
  '/p2p/api/funder/v2/tax/report'
];
export const TOTAL_FUNDING_GET: Endpoint = [
  'get',
  '/p2p/api/funder/total-pendanaan-berjalan'
];
export const VOUCHER_CHECK_POST: Endpoint = [
  'post',
  '/p2p/api/funder/voucher/check-voucher'
];
export const VOUCHER_DETAIL_GET: Endpoint = [
  'get',
  '/p2p/api/funder/voucher/details/:voucherCode'
];
export const VOUCHER_LIST_GET: Endpoint = [
  'get',
  '/p2p/api/funder/voucher/list'
];
export const VOUCHER_LIST_POST: Endpoint = [
  'post',
  '/p2p/api/funder/voucher/list'
];
export const FINANCIAL_DOCUMENT_LIST_GET: Endpoint = [
  'get',
  '/p2p/api/funder/v1/application/:applicationId/financial-docs'
];
export const DETAIL_FINANCIAL_DOCUMENT_GET: Endpoint = [
  'get',
  '/p2p/api/funder/v1/application/:applicationId/financial-docs/:financialDocType/:documentId/view'
];
export const DOWNLOAD_FINANCIAL_DOCUMENT_GET: Endpoint = [
  'get',
  '/p2p/api/funder/v1/application/:applicationId/financial-docs/download'
];
/* ======= */

/* URL used env.URL_SHARE */
export const BLOG_SHARE: Endpoint = ['get', '/wp-json/wp/v2/posts', true];
export const FAQ_POST_SHARE: Endpoint = [
  'get',
  '/wp-json/al-faq/v2/posts',
  true
];
export const FAQ_POST_SHARE_EN: Endpoint = [
  'get',
  '/en/wp-json/al-faq/v2/posts',
  true
];
export const FAQ_CATEGORY_SHARE: Endpoint = [
  'get',
  '/wp-json/al-faq/v2/category',
  true
];
export const FAQ_CATEGORY_SHARE_EN: Endpoint = [
  'get',
  '/en/wp-json/al-faq/v2/category',
  true
];
export const FAQ_VOTE_GET: Endpoint = [
  'get',
  '/wp-json/wp-ulike-pro/v1/user-status/:userId',
  true
];
export const FAQ_VOTE_POST: Endpoint = [
  'post',
  '/wp-json/wp-ulike-pro/v1/vote',
  true
];

/* Profile */
export const LIST_BANK_GET: Endpoint = [
  'get',
  '/p2p/api/funder/profile/bank-list'
];
export const BANK_ACCOUNT_ADD_POST: Endpoint = [
  'post',
  '/p2p/api/funder/v2/profile/bank-account/'
];
export const BANK_ACCOUNT_CHECK_POST: Endpoint = [
  'post',
  '/p2p/api/funder/v2/profile/bank-account/check'
];

/* Complete Profile */
export const COMPLETE_PROFILE_INSTITUTIONAL_CORPORATION_TYPE_GET: Endpoint = [
  'get',
  '/p2p/api/funder/institution-profile/corporate-type'
];
export const COMPLETE_PROFILE_INSTITUTIONAL_CORPORATION_TYPE_POST: Endpoint = [
  'post',
  '/p2p/api/funder/institution-profile/corporate-type'
];
export const COMPLETE_PROFILE_INSTITUTIONAL_DOCUMENT: Endpoint = [
  'post',
  '/p2p/api/funder/institution-profile/document'
];
export const COMPLETE_PROFILE_INSTITUTIONAL_AUTHORITY_HOLDER: Endpoint = [
  'post',
  '/p2p/api/funder/institution-profile/authority-holder'
];
export const COMPLETE_PROFILE_INSTITUTIONAL_STEP1: Endpoint = [
  'post',
  '/p2p/api/funder/institution-profile/representation/verify'
];
export const COMPLETE_PROFILE_INSTITUTIONAL_PROFILE_IMAGE: Endpoint = [
  'post',
  '/p2p/api/funder/v2/profile/image'
];
export const COMPLETE_PROFILE_INSTITUTIONAL_KTP_OCR: Endpoint = [
  'post',
  '/p2p/api/funder/institution-profile/ktp-ocr'
];
export const COMPLETE_PROFILE_INSTITUTIONAL_NPWP_OCR: Endpoint = [
  'post',
  '/p2p/api/funder/institution-profile/npwp-ocr'
];
export const COMPLETE_PROFILE_INSTITUTIONAL_SELFIE: Endpoint = [
  'post',
  '/p2p/api/funder/institution-profile/selfie'
];
export const COMPLETE_PROFILE_INSTITUTIONAL_FACE_COMPARISON: Endpoint = [
  'post',
  '/p2p/api/funder/institution-profile/face-comparison'
];

/* Education */
export const EDUCATION_CATEGORIES_GET: Endpoint = [
  'get',
  '/p2p/api/funder/education/categories'
];

/* ECOFIN */
export const REGISTRATION_AUTH: Endpoint = [
  'get',
  '/p2p/api/individual/registration/reference/:token'
];
export const REGISTRATION_POST: Endpoint = [
  'post',
  '/p2p/api/individual/registration/register-user/:token'
];
export const REGISTRATION_OTP_VALIDATE_POST: Endpoint = [
  'post',
  '/p2p/api/individual/registration/validate-otp/:token'
];
export const SEND_OTP_POST: Endpoint = [
  'post',
  '/p2p/api/individual/registration/send-otp/:token'
];
export const SECURITY_QUESTIONS_GET: Endpoint = [
  'get',
  '/p2p/api/individual/registration/security-questions'
];
export const INSTITUTION_CONTRACT_METHOD_POST: Endpoint = [
  'post',
  '/p2p/api/funder/v1/institution/contract'
];

/* ECOFIN PROFILE */
export const PROFILE_INDIVIDUAL_BENEF_GET: Endpoint = [
  'get',
  '/p2p/api/individual/profile/'
];
export const PROFILE_INDIVIDUAL_BENEF_SUMMARY_GET: Endpoint = [
  'get',
  '/p2p/api/individual/profile/summary'
];
export const PROFILE_INDIVIDUAL_BENEF_KTP_GET: Endpoint = [
  'get',
  '/p2p/api/individual/profile/check-ktp?identityNumber=:identityNumber&isSpouse=:isSpouse'
];
export const PROFILE_INDIVIDUAL_BENEF_PERSONAL_DATA_POST: Endpoint = [
  'post',
  '/p2p/api/individual/profile/personal/save-personal-1'
];
export const PROFILE_INDIVIDUAL_BENEF_PERSONAL_DATA_TWO_POST: Endpoint = [
  'post',
  '/p2p/api/individual/profile/personal/save-personal-2'
];
export const PROFILE_INDIVIDUAL_BENEF_DOCUMENT_POST: Endpoint = [
  'post',
  '/p2p/api/individual/document/upload'
];
export const PROFILE_INDIVIDUAL_BENEF_DOCUMENT_PHOTO_POST: Endpoint = [
  'post',
  '/p2p/api/individual/profile/photo'
];
export const PROFILE_INDIVIDUAL_BENEF_KTP_SELFIE_POST: Endpoint = [
  'post',
  '/p2p/api/individual/profile/face-comparison'
];
export const PROFILE_REGISTRATION_DOCUMENT: Endpoint = [
  'post',
  '/p2p/api/individual/registration/document-upload/:token'
];

export const PROFILE_INDIVIDUAL_BENEF_DOCUMENT_GET: Endpoint = [
  'get',
  '/p2p/api/individual/document/download/:fileName'
];
export const PROFILE_INDIVIDUAL_BENEF_PERSONAL_GET: Endpoint = [
  'get',
  '/p2p/api/individual/profile/personal'
];
export const PROFILE_INDIVIDUAL_BENEF_FAMILY_GET: Endpoint = [
  'get',
  '/p2p/api/individual/profile/family'
];
export const PROFILE_INDIVIDUAL_BENEF_FAMILY_POST: Endpoint = [
  'post',
  '/p2p/api/individual/profile/family'
];

export const PROFILE_INDIVIDUAL_BENEF_DOMICILE_POST: Endpoint = [
  'post',
  '/p2p/api/individual/profile/personal/save-domicile'
];

export const PROFILE_INDIVIDUAL_BENEF_PHOTO_DOCUMENT_GET: Endpoint = [
  'get',
  '/p2p/api/individual/profile/photo/:type'
];

export const PROFILE_INDIVIDUAL_BENEF_DOMICILE_GET: Endpoint = [
  'get',
  '/p2p/api/individual/profile/domicile-address'
];

export const PROFILE_INDIVIDUAL_BENEF_EMERGENCY_GET: Endpoint = [
  'get',
  '/p2p/api/individual/profile/getemergencycontact'
];

export const PROFILE_INDIVIDUAL_BENEF_EMERGENCY_POST: Endpoint = [
  'post',
  '/p2p/api/individual/profile/emergencycontact'
];

export const PROFILE_INDIVIDUAL_CURRENT_STEP_GET: Endpoint = [
  'get',
  '/p2p/api/individual/profile/current-step'
];

export const PROFILE_INDIVIDUAL_SET_NEXT_STEP_POST: Endpoint = [
  'post',
  '/p2p/api/individual/profile/set-next-step'
];

export const PROFILE_INDIVIDUAL_REQUEST_EMAIL_VERIFICATION_POST: Endpoint = [
  'post',
  '/p2p/api/individual/profile/email'
];

export const PROFILE_INDIVIDUAL_EMAIL_VERIFICATION_POST: Endpoint = [
  'post',
  '/p2p/api/individual/verify/email'
];

export const BUSINESS_INDIVIDUAL_RAB_GET: Endpoint = [
  'get',
  '/p2p/api/individual/business/rab'
];

export const BUSINESS_INDIVIDUAL_RAB_DEL: Endpoint = [
  'delete',
  '/p2p/api/individual/business/rab/:id/:ordering'
];

export const BUSINESS_INDIVIDUAL_RAB_POST: Endpoint = [
  'post',
  '/p2p/api/individual/business/rab'
];

export const BUSINESS_INDIVIDUAL_MASTER_DATA_INDUSTRIES_GET: Endpoint = [
  'get',
  '/p2p/api/individual/master/industries'
];

export const BUSINESS_INDIVIDUAL_MASTER_DATA_MONTHLY_INCOMES_GET: Endpoint = [
  'get',
  '/p2p/api/individual/master/monthly-incomes'
];

export const BUSINESS_INDIVIDUAL_MASTER_DATA_PAYMENT_SCHEMES_GET: Endpoint = [
  'get',
  '/p2p/api/individual/master/payment-schemes'
];

export const BUSINESS_INDIVIDUAL_MASTER_DATA_SOURCE_OF_FUNDS_GET: Endpoint = [
  'get',
  '/p2p/api/individual/master/source-of-funds'
];

export const BUSINESS_INDIVIDUAL_MASTER_DATA_UNITS_GET: Endpoint = [
  'get',
  '/p2p/api/individual/master/units'
];

export const BUSINESS_INDIVIDUAL_MASTER_DATA_USE_OF_PROCEEDS_GET: Endpoint = [
  'get',
  '/p2p/api/individual/master/use-of-proceeds'
];

export const BUSINESS_INDIVIDUAL_GENERAL_POST: Endpoint = [
  'post',
  '/p2p/api/individual/business/general'
];

export const BUSINESS_INDIVIDUAL_GENERAL_GET: Endpoint = [
  'get',
  '/p2p/api/individual/business/general'
];

export const REVIEW_PROFILE_BENEF_GET: Endpoint = [
  'get',
  '/p2p/api/individual/profile/summary-profile'
];

export const REVIEW_PROFILE_BENEF_POST: Endpoint = [
  'post',
  '/p2p/api/individual/profile/save-summary-profile'
];

export const REVIEW_BUSINESS_BENEF_GET: Endpoint = [
  'get',
  '/p2p/api/individual/business/review-business-data'
];

export const REVIEW_BUSINESS_BENEF_POST: Endpoint = [
  'post',
  '/p2p/api/individual/business/review-business-data'
];

export const FINANCING_INDIVIDUAL_BENEF_SUMMARY_GET: Endpoint = [
  'get',
  '/p2p/api/individual/financing/summary'
];

export const FINANCING_INDIVIDUAL_BENEF_LIMIT_GET: Endpoint = [
  'get',
  '/p2p/api/individual/financing/limit/installment'
];

export const PROFILE_INDIVIDUAL_SUBMIT_VERIFICATION_POST: Endpoint = [
  'post',
  '/p2p/api/individual/profile/required-data-submit'
];

export const PROFILE_INDIVIDUAL_CHECK_VERIFICATION: Endpoint = [
  'get',
  '/p2p/api/individual/profile/required-data-check'
];

export const PROFILE_INDIVIDUAL_GEOLOCATION_POST: Endpoint = [
  'post',
  '/p2p/api/individual/profile/geolocation-address-validated'
];

export const BUSINESS_INDIVIDUAL_ADDRESS_POST: Endpoint = [
  'post',
  '/p2p/api/individual/business/save-address'
];

export const BUSINESS_INDIVIDUAL_ADDRESS_GET: Endpoint = [
  'get',
  '/p2p/api/individual/business/get-address'
];

export const BUSINESS_INDIVIDUAL_SEARCH_ACCOUNT_BANK_POST: Endpoint = [
  'get',
  '/p2p/api/individual/master/search-account'
];

export const BUSINESS_INDIVIDUAL_SEARCH_BANK_GET: Endpoint = [
  'get',
  '/p2p/api/individual/master/search-bank-name'
];

export const BUSINESS_INDIVIDUAL_ADDITIONAL_DATA_GET: Endpoint = [
  'get',
  '/p2p/api/individual/profile/get-additional-data'
];

export const BUSINESS_INDIVIDUAL_ADDITIONAL_DATA_POST: Endpoint = [
  'post',
  '/p2p/api/individual/profile/save-additional-data'
];

export const INSTALLMENT_FINANCING_REVIEW_GET: Endpoint = [
  'get',
  '/p2p/api/individual/financing/:financingId/preview'
];

export const INSTALLMENT_FINANCING_REVIEW_TERMSHEET_GET: Endpoint = [
  'get',
  '/p2p/api/individual/financing/:financingId/termsheet'
];

export const INSTALLMENT_FINANCING_REVIEW_POST: Endpoint = [
  'post',
  '/p2p/api/individual/financing/:financingId/approve'
];

export const INSTALLMENT_FINANCING_LIST_GET: Endpoint = [
  'get',
  '/p2p/api/individual/financing/list/:status'
];

export const REVIEW_PROFILE_CREDIT_SCORING_RETAIL_INQUIRY: Endpoint = [
  'post',
  '/p2p/api/individual/integration/credit-scoring-retail/inquiry'
];

export const BUSINESS_INDIVIDUAL_FINANCING_DETAIL_GET: Endpoint = [
  'get',
  '/p2p/api/individual/financing/detail'
];

export const BUSINESS_INDIVIDUAL_TENOR_LIST_GET: Endpoint = [
  'get',
  '/p2p/api/individual/financing/tenor/:requestId'
];

export const BUSINESS_INDIVIDUAL_SUBMIT_TENOR_POST: Endpoint = [
  'post',
  '/p2p/api/individual/financing/submit/:requestId/:tenor'
];

export const BUSINESS_INDIVIDUAL_ADD_FINANCING_RAB_POST: Endpoint = [
  'post',
  '/p2p/api/individual/financing/rab/add/:requestId'
];

export const BUSINESS_INDIVIDUAL_DELETE_FINANCING_RAB_POST: Endpoint = [
  'post',
  '/p2p/api/individual/financing/rab/delete/:requestId/:itemId'
];

export const BUSINESS_INDIVIDUAL_FINANCING_RAB_GET: Endpoint = [
  'get',
  '/p2p/api/individual/financing/rab/get/:requestId'
];

export const BUSINESS_INDIVIDUAL_FINANCING_FORECAST_POST: Endpoint = [
  'post',
  '/p2p/api/individual/financing/:financingId/business/forecast/submit'
];

export const BUSINESS_INDIVIDUAL_FINANCING_FORECAST_GET: Endpoint = [
  'get',
  '/p2p/api/individual/financing/:financingId/business/forecast'
];
export const INDIVIDUAL_BUSINESS_REMINDER_AKAD_GET: Endpoint = [
  'get',
  '/p2p/api/individual/financing/waiting-akad/reminder'
];

export const INDIVIDUAL_BUSINESS_REMINDER_REPAYMENT_GET: Endpoint = [
  'get',
  '/p2p/api/individual/financing/reminder'
];

export const INDIVIDUAL_BUSINESS_REMINDER_COUNT_GET: Endpoint = [
  'get',
  '/p2p/api/individual/financing/reminder-count'
];

export const FINANCING_REMINDER_GET: Endpoint = [
  'get',
  '/p2p/api/individual/financing/reminder'
];

export const FINANCING_REMINDER_POST: Endpoint = [
  'post',
  '/p2p/api/individual/repayment/submit'
];

export const FINANCING_REMINDER_REPAYMENT_INFO_GET: Endpoint = [
  'get',
  '/p2p/api/individual/repayment/info/:id'
];

export const FINANCING_REMINDER_REPAYMENT_CANCEL_POST: Endpoint = [
  'post',
  '/p2p/api/individual/repayment/cancel/:actionId'
];

export const SIGN_AKAD_FINANCING_GET: Endpoint = [
  'get',
  '/p2p/api/individual/financing/:financingId/sign-akad'
];

export const AKAD_DOC_FINANCING_GET: Endpoint = [
  'get',
  '/p2p/api/individual/financing/:financingId/akad'
];
export const INDIVIDUAL_DIGISIGN_ACTIVATION_CALLBACK: Endpoint = [
  'post',
  '/p2p/api/individual/callback/activation'
];

/* USE FOR SIGNING AKAD AGRI AND ECOFIN */
export const INDIVIDUAL_DIGISIGN_SIGN_DOCUMENT_CALLBACK: Endpoint = [
  'post',
  '/p2p/api/individual/callback/:financingId/callback/sign-document'
];

export const FINANCING_STATUS_CHECK_GET: Endpoint = [
  'get',
  '/p2p/api/individual/financing/check'
];

export const RE_ASSESSMENT_GET: Endpoint = [
  'get',
  '/p2p/api/individual/financing/re-assessment'
];

export const PROFILE_INDIVIDUAL_BENEF_PHONE_NUMBER_POST: Endpoint = [
  'post',
  '/p2p/api/individual/profile/check-phone-number'
];

export const INDIVIDUAL_BUSINESS_VALIDATE_BUSINESS_DATE_GET: Endpoint = [
  'get',
  '/p2p/api/individual/business/validate-date'
];

export const INSTITUTIONAL_COMPANY_TYPE_GET: Endpoint = [
  'get',
  '/p2p/api/funder/institution-profile/getCompanyType'
];

export const INSTITUTIONAL_SUBINDUSTRY_GET: Endpoint = [
  'get',
  '/p2p/api/funder/profile/subIndustry/:parentId'
];

export const INSTITUTIONAL_SOURCE_GET: Endpoint = [
  'get',
  '/p2p/api/funder/master/v2/funding/sources'
];

export const INSTITUTIONAL_PURPOSE_GET: Endpoint = [
  'get',
  '/p2p/api/funder/master/v2/funding/purposes'
];

export const INSTALLMENT_TIMELINE_GET: Endpoint = [
  'get',
  '/p2p/api/funder/application/:idApplication/installment'
];

export const PAYMENT_VA_GET: Endpoint = [
  'get',
  '/p2p/api/funder/1.5/application/:idApplication'
];

export const UPLOAD_PAYMENT_PROOF_POST: Endpoint = [
  'post',
  '/p2p/api/funder/payment-proof'
];

export const DOC_PAYMENT_PROOF_GET: Endpoint = [
  'get',
  '/p2p/api/funder/1.5/application/:idApplication/payment-proof'
];

/* Agri */

export const REQUEST_RESET_LINK_REGISTRATION_GET: Endpoint = [
  'get',
  '/p2p/api/individual/registration/request-reset/:token'
];

export const PROFILE_INDIVIDUAL_BENEF_AGRI_SUMMARY_GET: Endpoint = [
  'get',
  '/p2p/api/individual/profile/agri/summary'
];

export const PROFILE_INDIVIDUAL_BENEF_AGRI_ACTIVATION_DIGISIGN_GET: Endpoint = [
  'get',
  '/p2p/api/individual/profile/digisign-activation'
];

export const PROFILE_INDIVIDUAL_BENEF_AGRI_REVIEW_GET: Endpoint = [
  'get',
  '/p2p/api/individual/registration/review-user/:token'
];

export const RELIGION_GET: Endpoint = [
  'get',
  '/p2p/api/individual/public/master/religion'
];

export const MARITAL_STATUS_GET: Endpoint = [
  'get',
  '/p2p/api/individual/public/master/marital-status'
];

export const OCCUPATION_GET: Endpoint = [
  'get',
  '/p2p/api/individual/public/master/occupation'
];

export const EDUCATION_GET: Endpoint = [
  'get',
  '/p2p/api/individual/public/master/education-level'
];

export const WORKING_EXPERIENCE_GET: Endpoint = [
  'get',
  '/p2p/api/individual/public/master/working-experince'
];

export const INDUSTRIES_GET: Endpoint = [
  'get',
  '/p2p/api/individual/public/master/industry'
];

export const MONTHLY_INCOME_GET: Endpoint = [
  'get',
  '/p2p/api/individual/public/master/monthly-incomes'
];

export const SOURCE_OF_FUND_GET: Endpoint = [
  'get',
  '/p2p/api/individual/public/master/source-of-funds'
];

export const USE_OF_PROCEED_GET: Endpoint = [
  'get',
  '/p2p/api/individual/public/master/use-of-proceeds'
];

export const LIST_PROVINCE_MASTER_GET: Endpoint = [
  'get',
  '/p2p/api/individual/public/master/location/:level'
];

export const LIST_LOCATION_MASTER_GET: Endpoint = [
  'get',
  '/p2p/api/individual/public/master/location/:level/:parentId'
];

export const UPDATE_USER_AGRI_REVIEW_DATA_POST: Endpoint = [
  'post',
  '/p2p/api/individual/registration/edit-benef/:token'
];

export const PROFILE_INDIVIDUAL_AGRI_SAVE_REVIEW_DATA_POST: Endpoint = [
  'post',
  '/p2p/api/individual/registration/update-review-benef/:token'
];

export const LIST_RAB_AGRI_GET: Endpoint = [
  'get',
  '/p2p/api/individual/financing/rab/get/:requestId'
];

export const AGRI_FINANCING_REQUEST_GET: Endpoint = [
  'get',
  '/p2p/api/individual/agri-financing/request'
];

export const ADD_RAB_AGRI_POST: Endpoint = [
  'post',
  '/p2p/api/individual/financing/rab/add/:requestId'
];

export const ERASE_RAB_AGRI_DELETE: Endpoint = [
  'post',
  '/p2p/api/individual/financing/rab/delete/:requestId/:rabId'
];

export const SUBMIT_RAB_AGRI_POST: Endpoint = [
  'post',
  '/p2p/api/individual/agri-financing/submit/:requestId/:tenor'
];

export const AGRI_FINANCING_LIST_GET: Endpoint = [
  'get',
  '/p2p/api/individual/agri-financing/list/:status'
];

export const AGRI_BENEF_SUMMARY_GET: Endpoint = [
  'get',
  '/p2p/api/individual/agri-financing/summary'
];

export const FINANCING_AGRI_APPROVAL_POST: Endpoint = [
  'post',
  '/p2p/api/individual/agri-financing/approval/:financingId/:status'
];

export const FINANCING_AGRI_CHECKOUT_REPAYMENT_GET: Endpoint = [
  'get',
  '/p2p/api/individual/agri-financing/checkout-repayment/list'
];

export const FINANCING_AGRI_WAIT_REPAYMENT: Endpoint = [
  'get',
  '/p2p/api/individual/agri-financing/checkout-repayment/wait-for-payment'
];
