import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import UNDERCONSTRUCTION from 'assets/icon/underconstruction.png';
import Button from 'components/funder/atoms/button';
import { handleUnderconstruction } from 'utils/clickElement';

const DialogUnderConstruction = () => {
  const { t } = useTranslation();

  const handleOpenUnder = useCallback(() => {
    return handleUnderconstruction();
  }, []);

  return (
    <>
      <input type='checkbox' id='underconstruction' className='modal-toggle' />
      <div className='modal'>
        <div className='modal-box max-w-[500px] !p-4'>
          <div className='flex flex-1 flex-col justify-center items-center'>
            <img src={UNDERCONSTRUCTION} alt='under' />
            <p className='mt-4 font-bold text-subtitle-1 tracking-subtitle-1'>
              {t('global.underConstruction1', 'Dalam Pengerjaan')}
            </p>
            <p className='mt-2 text-body-2 tracking-body-2'>
              {t(
                'global.underConstruction2',
                'Kami akan segera mengabari jika sudah selesai pengerjaan'
              )}
            </p>
          </div>
          <Button onClick={handleOpenUnder} styleClass='!mt-6 w-full'>
            OK
          </Button>
        </div>
      </div>
    </>
  );
};

export default DialogUnderConstruction;
