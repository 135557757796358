import { decryptionAES, encryptionAES } from 'utils/encryption';
import { IStorage } from 'service/storage-service/StorageService';
import { AUTH_USER } from 'commons/constants/variable';
import type { UserToken } from 'types/common';

export interface IAuthService {
  isAuthenticated(): boolean;
  getUser(): UserToken;
  setUser(data: UserToken): void;
  clearStorage(): void;
}

export class AuthService implements IAuthService {
  private storage: IStorage;

  private token: string | null;

  private user: UserToken | null;

  public static userKey = AUTH_USER;

  constructor(storage: IStorage) {
    this.storage = storage;
    this.token = null;
    this.user = {
      username: '',
      expiresIn: 0,
      refreshToken: '',
      token: '',
      userRoleTypeId: 0,
      userId: '',
      benefType: ''
    };
  }

  public isAuthenticated(): boolean {
    if (this.token !== null) {
      return true;
    }

    const token = this.storage.get(AUTH_USER);

    return token !== null;
  }

  public getUser(): UserToken {
    // Check if the token is in cache
    if (this.user?.username) {
      return this.user;
    }

    // Get the token from storage and already decrypted
    const user = this.storage.get(AuthService.userKey);
    const decryptedData = user ? decryptionAES(user) : null;
    return decryptedData;
  }

  public setUser(data: UserToken): void {
    // Set the token in cache
    this.user = data;

    // Save the token to storage and already encrypted
    if (Object.keys(data).length !== 0) {
      this.storage.set(AuthService.userKey, encryptionAES(data));
    }
  }

  public clearStorage(): void {
    this.token = null;
    this.user = null;
    this.storage.clear();
  }
}
