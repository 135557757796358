import CryptoJs from 'crypto-js';

type JsonSerializable =
  | string
  | number
  | boolean
  | null
  | JsonSerializable[]
  | { [key: string]: JsonSerializable };

const ENCRYPT_KEY = 'web-funder-alami';

export const encryptionAES = <T extends JsonSerializable>(value: T): string => {
  const encrypt = CryptoJs.AES.encrypt(
    JSON.stringify(value),
    ENCRYPT_KEY
  ).toString();
  return encrypt;
};

export const decryptionAES = (value?: string | undefined | null) => {
  if (!value) return '';

  const decrption = CryptoJs.AES.decrypt(value, ENCRYPT_KEY);

  return JSON.parse(decrption.toString(CryptoJs.enc.Utf8));
};
