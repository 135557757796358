import { storage } from 'utils/storage';
import { AuthService } from './auth-service/AuthService';
import { AccountService } from './account-service/AccountService';
import { MasterService } from './master-service/MasterService';
import { UploadService } from './upload-service/UploadService';
import { ProfileService } from './profile-service/ProfileService';
import { RdfService } from './rdf-service/RdfService';
import { CampaignService } from './campaign-service/CampaignService';

export const authService = new AuthService(storage);

export const accountService = new AccountService({
  authService
});

export const masterService = new MasterService({
  authService
});

export const uploadService = new UploadService({
  authService
});

export const profileService = new ProfileService({
  authService
});

export const rdfService = new RdfService({
  authService
});

export const campaignService = new CampaignService({
  authService
});
