import { Suspense } from 'react';
import type { LazyExoticComponent } from 'react';

import Loading from 'components/funder/molecules/loading';

const WithSuspense = (Component: LazyExoticComponent<any>) => {
  return (
    <Suspense fallback={<Loading isLoading />}>
      <Component />
    </Suspense>
  );
};

export default WithSuspense;
