import { z } from 'zod';

export const statusTransferTypeSchema = z.object({
  transferTypes: z.array(z.string())
});

export type StatusTransferType = z.infer<typeof statusTransferTypeSchema>;

export const destionationBankSchema = z.object({
  code: z.string(),
  name: z.string(),
  id: z.string(),
  interbankCode: z.string(),
  clearingCode: z.string(),
  rtgsCode: z.string(),
  instamoneyCode: z.string()
});

export const withdrawalAccountSchema = z.object({
  lenderId: z.string(),
  sourceAccountName: z.string(),
  sourceAccountNumber: z.string(),
  destinationAccountName: z.string(),
  destinationAccountNumber: z.string(),
  destinationBank: destionationBankSchema,
  amount: z.number().nullable(),
  rdlBankCharge: z.number().nullable()
});

export type WithdrawalAccount = z.infer<typeof withdrawalAccountSchema>;

export const withdrawalListSchema = z.array(
  z.object({
    id: z.string(),
    requestDate: z.string(),
    sourceAccountName: z.string(),
    sourceAccountNumber: z.string(),
    destinationAccountName: z.string(),
    destinationAccountNumber: z.string(),
    destinationBank: destionationBankSchema,
    amount: z.number(),
    statusCode: z.null(),
    requestUuid: z.null(),
    verifyStatus: z.object({
      name: z.string(),
      activityCode: z.string(),
      id: z.string(),
      activityMessage: z.string()
    }),
    comment: z.null(),
    rdlBankCharge: z.object({
      chargeType: z.string(),
      description: z.string(),
      descriptionId: z.string(),
      descriptionEn: z.string(),
      chargeAmount: z.number(),
      codeMessage: z.string(),
      codeMessageDetail: z.null(),
      id: z.string()
    }),
    transferCharge: z.number(),
    amountReceived: z.number(),
    processDate: z.string().nullable()
  })
);

export type WithdrawalList = z.infer<typeof withdrawalListSchema>;

export type WithdrawalAmountParam = {
  amount: number;
  rdlBankCharge: {
    id: string;
  };
};

export const lenderHoldAmountSchema = z.object({
  id: z.string(),
  application: z.object({
    id: z.string(),
    applicationUser: z.string(),
    currency: z.number(),
    financingTypeId: z.string(),
    submissionTimestamp: z.string(),
    financingSize: z.number(),
    useOfProceeds: z.string(),
    verifyStatus: z.string(),
    verifiedBy: z.string(),
    verifiedDate: z.string(),
    campaignDate: z.string(),
    fundingDate: z.string(),
    campaignEndDate: z.string(),
    disburseDate: z.string(),
    repaymentDate: z.string(),
    videoUrl: z.string(),
    imageCover: z.string(),
    comment: z.string(),
    isAdminView: z.boolean(),
    flowType: z.string(),
    returnMargin: z.number(),
    factSheet: z.string(),
    factSheetEn: z.string(),
    jatuhTempo: z.string(),
    portfolioStatus: z.string(),
    portfolioSubStatus: z.string(),
    financingSizeApprove: z.number(),
    financingSizeFix: z.number(),
    paymentMethodApproved: z.string(),
    mup: z.string(),
    creditScoring: z.string(),
    summaryDisclaimer: z.boolean(),
    commitmentMembership: z.string(),
    analystFullCheck: z.boolean(),
    emailCampaignFullFunded: z.string(),
    emailCampaign2DaysLeft: z.boolean(),
    privateCampaign: z.string(),
    hiddenCampaign: z.string(),
    alamiMargin: z.number(),
    loaNo: z.string(),
    prefixProject: z.string(),
    offeringLetterNo: z.string(),
    noUrutAkad01: z.number(),
    campaignFlag: z.number(),
    report: z.string(),
    rekeningKoran: z.string(),
    historyInvoice: z.string(),
    kontrak: z.string(),
    offeringLetter: z.string(),
    qardh: z.string(),
    wakalahBilUjroh: z.string(),
    personalGuarantee: z.string(),
    waad: z.string(),
    akad01: z.string(),
    minFunding: z.number(),
    maxFunding: z.number(),
    campaignRoomType: z.string(),
    remainingAmount: z.number(),
    qardEnable: z.boolean(),
    wakalahBilUjrahEnable: z.boolean(),
    hawalahBilUjrahEnable: z.boolean(),
    murabahahEnable: z.boolean(),
    musyarakahEnable: z.boolean(),
    mudharabahEnable: z.boolean(),
    mmbtEnable: z.string(),
    ijarahEnable: z.string(),
    mmqEnable: z.boolean(),
    useOfProceedsObj: z.string(),
    applicationLabelId: z.string(),
    creditEngines: z.string(),
    applicationLenders: z.string(),
    voucherApplications: z.string(),
    applicationInstallments: z.string(),
    applicationInsurances: z.string(),
    capacity1: z.string(),
    capacity2: z.string(),
    capacity3: z.string(),
    capacity4: z.string(),
    character: z.string(),
    collaterals: z.string(),
    openedByBank: z.string(),
    totalLender: z.number(),
    repaymentAmount: z.string(),
    pendanaan: z.string(),
    pendanaanValid: z.string(),
    pendanaanTersedia: z.string(),
    progressPendanaan: z.string(),
    progressPendanaanValid: z.string(),
    status: z.string(),
    amountBorrower: z.string(),
    individualId: z.string(),
    payorName: z.string(),
    financingTenorApproved: z.string()
  }),
  lender: z.string(),
  amount: z.number(),
  ujrohFinal: z.string(),
  amountBookedDate: z.string(),
  amountBookedDate2: z.string(),
  buktiBayar: z.string(),
  poaId: z.string(),
  buktiBayarDate: z.string(),
  verifyStatus: z.string(),
  verifiedBy: z.string(),
  verifiedDate: z.string(),
  bankAccount: z.string(),
  comment: z.string(),
  ivsNo: z.string(),
  dateUuid: z.string(),
  vaNumber: z.string(),
  t2StatusType: z.string(),
  repaid: z.boolean(),
  forceActive: z.boolean(),
  investWithRdl: z.boolean(),
  transferRdlDate: z.string(),
  vaNumberBni: z.string(),
  manualBook: z.string(),
  transferRdlRequestUuid: z.string(),
  repaymentRdlDate: z.string(),
  repaymentRdlRequestUuid: z.string(),
  ujrohComfin: z.number(),
  reward: z.boolean(),
  voucherCode: z.string(),
  lampiranFundingApproveId: z.string(),
  akadPayungUsage: z.boolean(),
  ujrohTax: z.number(),
  ujrohTaxRate: z.number(),
  ujrohFinalAfterTax: z.number(),
  serviceFeeAmount: z.number(),
  serviceFeeTaxAmount: z.string(),
  applicationLenderPartialPayments: z.string(),
  noAkad03: z.string(),
  transactionFlag: z.number(),
  ujrohBeforeTax: z.number(),
  taxes: z.number(),
  ujrohAfterTax: z.number(),
  totalReturnAmount: z.number(),
  applicationStatusFlag: z.number(),
  chipInPlatform: z.string()
});

export const accountBalanceSchema = z.object({
  accountNumber: z.string(),
  customerName: z.string(),
  accountBalance: z.string(),
  holdAmount: z.number(),
  pendingWithdrawal: z.number(),
  balanceWithHoldAmount: z.number(),
  lenderHoldAmounts: z.array(lenderHoldAmountSchema),
  nextBalanceCheckIn: z.string().nullable(),
  pendingDisbursementHoldAmount: z.number(),
  activeFunding: z.number()
});

export type AccountBalance = z.infer<typeof accountBalanceSchema>;
