export const storage = {
  get: (key: string): string | null => {
    return localStorage.getItem(key);
  },
  set: <T>(key: string, value: T): void => {
    localStorage.setItem(key, value as string);
  },
  remove: (key: string): void => {
    localStorage.removeItem(key);
  },
  clear: (): void => {
    localStorage.clear();
  }
};
