import React, { PropsWithChildren } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { LOGIN } from 'commons/constants/routePath';
import { useAuth } from 'hoc/context/authprovider';

const RouteAuth: React.FC<PropsWithChildren> = ({ children = null }) => {
  const { user } = useAuth();
  const location = useLocation();

  if (!user?.username) {
    return <Navigate to={LOGIN} state={{ from: location }} replace />;
  }

  return <>{children}</>;
};

export default RouteAuth;
