import { z } from 'zod';

export type ParamLoginAccount = {
  username: string;
  password: string;
};

export type UserTracking = {
  partnerId: string;
  userId: string;
  nominatifId: string;
  akadId: string;
};

export type DocumentRegister = {
  id: number;
  orderNumber: number;
  detailsUrl: string;
  slug: string;
  snippets: string | null;
  title: string;
};

export type ListDocumentRegister = {
  documents: DocumentRegister[];
};

export type ParamRegisterInstitutionalFunder = {
  citizenshipId: string;
  corporateTypeId: string;
  corporateTypeOthers: string | null;
  companyTypeId: string;
  companyTypeOthers: string | null;
  companyName: string;
  companyEmail: string;
  allowInfoSubscription: boolean;
  agreedToTerm: boolean;
  agreedToPojk111: boolean;
};

export type ParamRegisterRetailFunder = {
  email: string;
  relamiCode: string | null;
  phone: string;
};

export type ParamVerifyRelami = {
  relamiCode: string;
};

export type RelamiResponse = {
  message: string;
  isExist: boolean;
};

export type ParamApproveTnc = {
  email: string;
  subscribeInfo: boolean;
  agreedToTerm: boolean;
  agreedToPojk111: boolean;
};

export type ResendEmailVerificationOrCreatePasswordResponse = {
  success: boolean;
  id: string;
  resendActivationMailCount: number | null;
  maxDailyRequestActivationMail: number | null;
};

export type ParamCreatePassword = {
  key: string;
  newPassword: string;
  confirmPassword: string;
};

export const verifyUserRespSchema = z.object({
  isVerify: z.boolean().nullable(),
  isNewVerified: z.boolean().nullable(),
  isDigisignActive: z.boolean().nullable(),
  isRdlActive: z.boolean().nullable(),
  hasBankAccount: z.boolean().nullable(),
  needRetakeOcr: z.boolean().nullable(),
  needChangeProfile: z.boolean().nullable(),
  isVerificationRejected: z.boolean().nullable(),
  changeProfileOnSteps: z.array(z.number()).nullable(),
  verificationWaitingDays: z.number().nullable(),
  funderId: z.string().nullable(),
  name: z.string().nullable(),
  gender: z.number().nullable(),
  email: z.string().nullable(),
  phoneNumber: z.string().nullable(),
  nationality: z.number().nullable(),
  userType: z.number().nullable(),
  accountNumber: z.string().nullable(),
  rejectComment: z.string().nullable(),
  lastRepaymentDate: z.date().nullable(),
  agreementSignedDate: z.string().nullable(),
  taxRate: z.number().nullable(),
  profileStep: z.number().nullable(),
  rdlEducationLevelId: z.string().nullable(),
  rdlMonthlyIncomeId: z.string().nullable(),
  completeProfile: z.number().nullable(),
  pin: z.string().nullable(),
  isPinValid: z.boolean().nullable(),
  pinFailedCount: z.number().nullable(),
  canChangePinIn: z.number().nullable(),
  restrictedUser: z.boolean().nullable(),
  usingAkadPayung: z.boolean().nullable(),
  isFunderFI: z.boolean().nullable()
});

export type VerifyUser = z.infer<typeof verifyUserRespSchema>;

export type ParamCheckBankAccount = {
  bankId: string;
  ownerAccount: string;
};

export const accountBankSchema = z.object({
  bankId: z.string(),
  ownerAccount: z.string(),
  ownerName: z.string(),
  success: z.boolean(),
  match: z.string()
});

export type AccountBankResponse = z.infer<typeof accountBankSchema>;

export type ParamAddBankAccount = {
  bankId: string;
  ownerAccount: string;
  fullName: string;
  password: string;
};

export type VerifyUserOutlet = {
  isLoading?: boolean;
  refetchVerifyUser?: () => void;
  refetchInstitutionalContract?: () => void;
} & VerifyUser;
