export const isRecommendedBrowser = () => {
  const sUsrAg = navigator.userAgent;

  if (sUsrAg.indexOf('Browser') > -1) {
    return false;
  }

  if (
    sUsrAg.indexOf('Firefox') > -1 ||
    sUsrAg.indexOf('Opera') > -1 ||
    sUsrAg.indexOf('OPR') > -1 ||
    sUsrAg.indexOf('Chrome') > -1
  ) {
    return true;
  }

  return false;
};

export const isIos = navigator.userAgent.match(/iPhone|iPod|iPad/);
