import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import classNames from 'classnames';
import Carousel from 'components/ecofin/carousel';
import Button from 'components/funder/atoms/button';
import { ECOFIN_REGISTER } from 'commons/constants/routePath';
import { useLocation, useNavigate } from 'react-router-dom';
import umkm from 'assets/graphic/usp/umkm.png';
import mosque from 'assets/graphic/usp/mosque.png';
import benefits from 'assets/graphic/usp/benefits.png';
import Ticker from 'components/ecofin/ticker';
import useMediaCustom from 'hook/useMedia';
import { isIos, isRecommendedBrowser } from 'utils/checkDevice';

const Usp = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const slides = 3;
  const [slideIndex, setSlideIndex] = useState(0);
  const { isMobileM } = useMediaCustom();

  const widthBox = isMobileM ? 'w-[325px]' : 'w-[360px] -mt-12';
  const widthContainer = isMobileM && 'w-[100vw]';

  const slickRef = useRef<any>();

  const handleRegister = () => {
    navigate({ pathname: ECOFIN_REGISTER, search });
  };

  const fontSize = useMemo(() => {
    return window.screen.height < 667 ? 'font-[25px]' : 'text-subtitle-1';
  }, []);

  const tickerOnlyStyle = isIos || !isRecommendedBrowser();

  const screenHeightAdjustment = useCallback(() => {
    for (let i = 1; i <= 3; i += 1) {
      const rootElement = document.getElementById(`section-${i}`);
      rootElement?.style.removeProperty('width');
    }
  }, []);

  useEffect(screenHeightAdjustment, [screenHeightAdjustment]);

  const renderInfo = () => {
    if (isIos) {
      return (
        <Ticker
          showIcon
          content='<strong>Maaf, saat ini pendaftaran belum tersedia di Iphone</strong><br/>Gunakan handphone Android atau perangkat desktop untuk mendaftar menjadi Penerima Dana ALAMI.'
          type='danger'
          className='!mt-4'
        />
      );
    }
    if (!isRecommendedBrowser()) {
      return (
        <Ticker
          showIcon
          content='<strong>Rekomendasi Browser</strong><br/>Untuk performa yang lebih baik, pastikan untuk menggunakan browser Google Chrome, Firefox, dan Opera.'
          type='info'
        />
      );
    }
    return (
      <div className='flex flex-col gap-7 w-full my-5'>
        <Button
          variant='primary'
          styleClass='flex-1 mr-3 w-full'
          onClick={handleRegister}
          id='registration_button'
        >
          Jadi Penerima Dana Sekarang
        </Button>

        <a
          href='/'
          className='text-primary !border-white font-bold text-center'
        >
          Sudah Punya Akun? Masuk
        </a>
      </div>
    );
  };

  return (
    <div
      className={`h-screen-xs-full bg-[url('assets/graphic/usp/ornament.png')] bg-no-repeat bg-right-top bg-contain`}
    >
      <Carousel
        ref={slickRef}
        sliderSettings={{
          draggable: true,
          swipeToSlide: true,
          swipe: true,
          beforeChange: (_, nextSlide) => {
            setSlideIndex(nextSlide);
          }
        }}
      >
        <div
          className={`!flex !flex-col justify-center items-center h-screen-xs-full px-5 ${widthContainer}`}
          id='section-1'
        >
          <div
            className={`flex flex-col items-center justify-center gap-2 ${
              tickerOnlyStyle && '-mt-[200px]'
            } ${widthBox}`}
          >
            <img src={umkm} alt='umkm' className='mb-5' />
            <h1 className={`${fontSize} font-bold text-center`}>
              Kembangkan Bisnismu di ALAMI
            </h1>
            <p className='text-subtitle-1 leading-[27px] text-light-grey font-normal text-center'>
              Selamat datang! Ribuan UMKM telah mendapatkan pembiayaan total
              hingga miliaran rupiah.
            </p>
          </div>
        </div>

        <div
          className={`!flex !flex-col justify-center items-center h-screen-xs-full px-4 ${widthContainer}`}
          id='section-2'
        >
          <div
            className={`flex flex-col items-center justify-center gap-2 ${
              tickerOnlyStyle && '-mt-[200px]'
            } ${widthBox}`}
          >
            <img src={mosque} alt='mosque' className='mb-5' />
            <h1 className={`${fontSize} font-bold text-center`}>
              Pembiayaan Mudah, Aman, dan Sesuai Syariah
            </h1>
            <p className='text-subtitle-1 leading-[27px] text-light-grey font-normal text-center'>
              Proses pengajuan mudah, aman dan sesuai syariah. ALAMI telah resmi
              terdaftar dan diawasi oleh OJK.
            </p>
          </div>
        </div>

        <div
          className={`!flex !flex-col justify-center items-center h-screen-xs-full px-5 ${widthContainer}`}
          id='section-3'
        >
          <div
            className={`flex flex-col items-center justify-center gap-2 ${
              tickerOnlyStyle && '-mt-[200px]'
            } ${widthBox}`}
          >
            <img src={benefits} alt='benefits' className='mb-5' />
            <h1 className={`${fontSize} font-bold text-center`}>
              Banyak Manfaat Menjadi Penerima Dana di ALAMI
            </h1>
            <p className='text-subtitle-1 leading-[27px] text-light-grey font-normal text-center'>
              Dapatkan modal usaha tinggi dengan bagi hasil dan biaya layanan
              yang rendah
            </p>
          </div>
        </div>
      </Carousel>

      <div className='absolute w-full max-w-screen-xs bottom-0 flex flex-col items-center z-[1] px-5 pt-5'>
        <div className='flex bg-light-grey-border2 rounded-[30px]'>
          {[...Array(slides)].map((_, index) => {
            return (
              <div
                key={`slide-${uuidv4()}`}
                className={classNames('flex-1 h-2 w-[14.6px] rounded-[30px]', {
                  'bg-primary': index === slideIndex,
                  'bg-light-grey-border2': index !== slideIndex
                })}
              />
            );
          })}
        </div>
        {renderInfo()}
      </div>
    </div>
  );
};

export default Usp;
