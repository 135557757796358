/* globals VoucherUser */
import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react';
import { useQuery } from '@tanstack/react-query';

import { getListVoucher } from 'queries/voucher';

import { useAuth } from './authprovider';

type VoucherContextType = {
  getListVoucher: VoucherUser[];
  selectVoucher: {
    campaignId: string;
    voucherCode: string;
    voucherReward: number;
  };
  onSelectVoucher: (
    voucherSelect: Partial<VoucherContextType['selectVoucher']>
  ) => void;
  totalVoucher: number;
};

const VoucherContext = createContext<VoucherContextType>(null!);

const VoucherProvider: React.FC<PropsWithChildren> = ({ children = null }) => {
  const { user } = useAuth();

  const [selectVoucher, setSelectVocher] = useState<
    VoucherContextType['selectVoucher']
  >({
    campaignId: '',
    voucherCode: '',
    voucherReward: 0
  });

  const { data: dataVoucher } = useQuery<{
    vouchers: VoucherUser[];
  }>(['list-voucher'], getListVoucher, {
    initialData: {
      vouchers: []
    },
    enabled: !!user.token
  });

  const onSelectVoucher = useCallback(
    ({
      campaignId,
      voucherCode,
      voucherReward
    }: Partial<VoucherContextType['selectVoucher']>) => {
      setSelectVocher({
        campaignId: campaignId || '',
        voucherCode: voucherCode || '',
        voucherReward: voucherReward || 0
      });
    },
    []
  );

  const value = useMemo(() => {
    return {
      getListVoucher: dataVoucher.vouchers,
      selectVoucher,
      onSelectVoucher,
      totalVoucher: dataVoucher.vouchers.length ?? 0
    };
  }, [dataVoucher.vouchers, onSelectVoucher, selectVoucher]);

  return (
    <VoucherContext.Provider value={value}>{children}</VoucherContext.Provider>
  );
};

export default VoucherProvider;

export const useVoucher = () => {
  return useContext(VoucherContext);
};
