/*
BENEFICIARY_ECO
Funder Badan Hukum
auto redirect dev.p2p.alamisharia.co.id.
untuk list dibawah ini will soon
Funder Retail WNA
Benef Non ECO.
*/

export const AUTHENTICATION_TYPE = {
  FUNDER_RETAIL_WNI: 1,
  FUNDER_RETAIL_WNA: 2,
  FUNDER_BADAN_HUKUM: 3,
  BENEFICIARY_ECO: 4,
  BENEFICIARY_NON_ECO: 5,
  FUNDER_BADAN_HUKUM_FI: 6
};

export const ServiceFeeAuthentication = {
  FI: 0.25,
  WNI_WNA_NONFI: 1
};

export const TaxAuthentication = {
  FI: 0,
  WNI_WNA_NONFI: 15
};
