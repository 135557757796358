import type { UseQueryResult } from '@tanstack/react-query';
import { AUTHENTICATION_TYPE } from 'commons/constants/authenticationType';
import {
  AGRI_EXPIRED_LINK,
  AGRI_REVIEW_PROFILE_REGISTER,
  ECOFIN,
  ECOFIN_NOTFOUND,
  ECOFIN_REGISTER,
  FUNDER_DASHBOARD,
  LOGIN
} from 'commons/constants/routePath';
import {
  AGRI_USER_STATUS_REGISTER,
  EXPIRED_RESPONSE_MESSAGE
} from 'commons/constants/variable';
import Loading from 'components/funder/molecules/loading';
import { useAuth } from 'hoc/context/authprovider';
import { type ResponseRegistrationAuth } from 'hook/useRegistrationAuth';
import Usp from 'pages/ecofin/usp';
import React, { PropsWithChildren } from 'react';
import { Navigate, useLocation, useSearchParams } from 'react-router-dom';
import { isIos, isRecommendedBrowser } from 'utils/checkDevice';

interface IEcofinAuth extends PropsWithChildren {
  dataRegistrationAuth: UseQueryResult<ResponseRegistrationAuth, Error>;
}

const EcofinAuth: React.FC<IEcofinAuth> = ({
  dataRegistrationAuth,
  children = null
}) => {
  const { user } = useAuth();
  const location = useLocation();
  const pathname = location.pathname.endsWith('/')
    ? location.pathname.slice(0, -1)
    : location.pathname;
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const isOnboardingPage = [ECOFIN, ECOFIN_REGISTER, ECOFIN_NOTFOUND].some(
    (s) => {
      return s.toLowerCase() === pathname.toLowerCase();
    }
  );

  const { data, error, isFetching } = dataRegistrationAuth;

  const { BENEFICIARY_ECO } = AUTHENTICATION_TYPE;

  const isValid = data?.statusCode === 200;

  const isNotFound =
    isOnboardingPage && (!token || !isValid) && pathname !== ECOFIN_NOTFOUND;

  if (isFetching) return <Loading isLoading />;

  if (isIos || !isRecommendedBrowser()) {
    return <Usp />;
  }

  if (error?.message === EXPIRED_RESPONSE_MESSAGE) {
    return (
      <Navigate
        to={AGRI_EXPIRED_LINK}
        state={{ from: location, token }}
        replace
      />
    );
  }

  if (isNotFound) {
    return <Navigate to={ECOFIN_NOTFOUND} state={{ from: location }} replace />;
  }

  const listPageUnauthorized = new Set([
    ECOFIN_NOTFOUND,
    AGRI_EXPIRED_LINK,
    AGRI_REVIEW_PROFILE_REGISTER
  ]);
  const isPageUnauthorized = listPageUnauthorized.has(pathname);

  const isUnauthorized =
    !isOnboardingPage && !user?.token && !isPageUnauthorized;

  const isUserAgriActive =
    data?.body.partnerInfo?.userStatus === AGRI_USER_STATUS_REGISTER.ACTIVE;

  const isUserRegistered =
    data?.body.partnerInfo?.userStatus === AGRI_USER_STATUS_REGISTER.REGISTERED;

  if (isUserRegistered) {
    return (
      <Navigate
        to={AGRI_REVIEW_PROFILE_REGISTER}
        state={{ from: location, token }}
        replace
      />
    );
  }

  if (isUnauthorized || isUserAgriActive) {
    return <Navigate to={LOGIN} state={{ from: location }} replace />;
  }

  const listPageExpectAuthFunding = new Set([
    AGRI_EXPIRED_LINK,
    AGRI_REVIEW_PROFILE_REGISTER
  ]);
  const isPageAuthFunding = listPageExpectAuthFunding.has(pathname);

  const isUserFunding =
    !isOnboardingPage &&
    BENEFICIARY_ECO !== user?.userRoleTypeId &&
    !isPageAuthFunding;

  if (isUserFunding) {
    return <Navigate to={FUNDER_DASHBOARD} replace />;
  }

  return <>{children}</>;
};

export default EcofinAuth;
