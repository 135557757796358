import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import api from 'utils/api';
import {
  I18N_MESSAGE_GET,
  I18N_VERSION_GET
} from 'commons/constants/endpoints';
import { getLocalStorage, setLocalStorage } from 'utils/localStorage';
import {
  I18NEXT_LANG,
  I18NEXT_MESSAGE_EN,
  I18NEXT_MESSAGE_ID,
  I18NEXT_VERSION
} from 'commons/constants/variable';

const languageDetector = {
  type: 'languageDetector',
  async: true,
  detect: (callback: any) => {
    // You'll receive a callback if you passed async true
    /* return detected language */
    // callback('de'); if you used the async flag
    const getLanguange = getLocalStorage(I18NEXT_LANG);
    callback(getLanguange || 'id');
  },
  init: () => {
    /* use services and options */
  },
  cacheUserLanguage: () => {
    /* cache language */
  }
};

i18n
  .use(Backend)
  .use(initReactI18next)
  .use(languageDetector as any)
  .init({
    load: 'languageOnly',
    fallbackLng: 'id',
    debug: false && window.__RUNTIME_CONFIG__.NODE_ENV === 'development',
    compatibilityJSON: 'v3',
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    react: {
      useSuspense: true
    },
    backend: {
      crossDomain: true,
      request: async (options, url, payload, callback) => {
        const getVersion = getLocalStorage(I18NEXT_VERSION);
        const getMessageID = getLocalStorage(I18NEXT_MESSAGE_ID);
        const getMessageEN = getLocalStorage(I18NEXT_MESSAGE_EN);

        const languange = url.split('/')[2];
        const resVersion = await api({
          endpoint: I18N_VERSION_GET
        });

        if (
          !getVersion ||
          getVersion === 'undefined' ||
          (resVersion?.data &&
            JSON.parse(getVersion)[languange] !== resVersion.data[languange])
        ) {
          try {
            const resMessageID = await api({
              endpoint: I18N_MESSAGE_GET,
              params: {
                lang: 'id'
              }
            });
            const resMessageEN = await api({
              endpoint: I18N_MESSAGE_GET,
              params: {
                lang: 'en'
              }
            });
            if (resVersion.data) {
              setLocalStorage(I18NEXT_VERSION, JSON.stringify(resVersion.data));
            }
            if (resMessageID.data) {
              setLocalStorage(
                I18NEXT_MESSAGE_ID,
                JSON.stringify(resMessageID.data)
              );
            }
            if (resMessageEN.data) {
              setLocalStorage(
                I18NEXT_MESSAGE_EN,
                JSON.stringify(resMessageEN.data)
              );
            }
            if (resMessageID.data || resMessageEN.data) {
              callback(null, {
                data: JSON.stringify(
                  languange === 'id' ? resMessageID.data : resMessageEN.data
                ),
                status: 200
              });
            } else {
              callback(null, {
                data: JSON.stringify({}),
                status: 200
              });
            }
          } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error);
          }
        } else {
          let message = '';
          if (languange === 'id') {
            message = getMessageID || '';
          }
          if (languange === 'en') {
            message = getMessageEN || '';
          }
          callback(null, {
            data: message,
            status: 200
          });
        }
      }
    }
  });

export default i18n;
