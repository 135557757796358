export const overflowHide = (isShow: boolean) => {
  const root = document.body;

  if (root && isShow) {
    const hasNoScroll = root.classList.contains('noscroll');
    if (!hasNoScroll) {
      root.classList.add('noscroll');
    }
  } else {
    root?.classList.remove('noscroll');
  }
};
