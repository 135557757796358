import React from 'react';
import classNames from 'classnames';

import { ReactComponent as AlertWarning } from 'assets/icon/alert-warning.svg';
import { ReactComponent as CheckSuccess } from 'assets/icon/check-green2.svg';
import { ReactComponent as CloseRound } from 'assets/icon/close-rounded.svg';

type SnackbarProps = {
  isOpen: boolean;
  isSuccess: boolean;
  message: string;
  handleClose: () => void;
};

const Snackbar: React.FC<SnackbarProps> = ({
  isOpen,
  isSuccess,
  message,
  handleClose
}) => {
  return (
    <div
      className={classNames(
        'sm:min-w-[345px] min-w-full rounded-lg z-[999] fixed flex md:bottom-6 bottom-[5%] md:-translate-x-[50%] md:left-1/2 left-[2.5%] transition-transform duration-[225ms] ease-in-out-[cubic-bezier(0,0,0.2,1)] delay-[0ms]',
        {
          'translate-y-[200vh]': !isOpen,
          'translate-[none]': isOpen
        }
      )}
      id={isSuccess ? 'snackbar_success' : 'snackbar_error'}
    >
      <div
        className={classNames(
          'flex sm:w-full w-[95%] p-2 rounded-lg text-white text-caption tracking-caption items-center',
          {
            'bg-error-primary': !isSuccess,
            'bg-success-primary': isSuccess
          }
        )}
      >
        <span className='flex'>
          {isSuccess ? <CheckSuccess /> : <AlertWarning />}
        </span>
        <span className='w-full mx-3'>{message}</span>
        <button onClick={handleClose} type='button'>
          <span className='flex'>
            <CloseRound width={20} height={20} />
          </span>
        </button>
      </div>
    </div>
  );
};

export default Snackbar;
