export const handleUnderconstruction = () => {
  document.getElementById('underconstruction')?.click();
  const elementRoot = document.body;
  const noScrollClass = elementRoot?.classList.contains('noscroll');

  if (noScrollClass) {
    elementRoot?.classList.remove('noscroll');
  } else {
    elementRoot?.classList.add('noscroll');
  }
};

export const handleNoScrollModalOpen = (id: string) => {
  document.getElementById(id)?.click();
  const elementRoot = document.body;
  const noScrollClass = elementRoot?.classList.contains('noscroll');

  if (noScrollClass) {
    elementRoot?.classList.remove('noscroll');
  } else {
    elementRoot?.classList.add('noscroll');
  }
};
