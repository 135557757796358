import {
  VOUCHER_CHECK_POST,
  VOUCHER_DETAIL_GET,
  VOUCHER_LIST_GET,
  VOUCHER_LIST_POST
} from 'commons/constants/endpoints';
import api from 'utils/api';

type Voucher = {
  campaignId: string;
  chipInAmount: number;
  voucherCode: string;
};

export const getListVoucher = async () => {
  const { data, message } = await api({
    endpoint: VOUCHER_LIST_GET
  });

  if (message) {
    throw new Error(message);
  }

  return data || {};
};

export const postListVoucherChipIn = async (value: any) => {
  const { campaignId, chipInAmount } = value;
  const { data, message } = await api({
    endpoint: VOUCHER_LIST_POST,
    body: {
      applicationId: campaignId,
      chipInAmount
    }
  });

  if (message) {
    throw new Error(message);
  }

  return data;
};

export const getDetailVoucher = async ({ queryKey }: any) => {
  const response = await api({
    endpoint: VOUCHER_DETAIL_GET,
    params: {
      voucherCode: queryKey[1]
    }
  });

  if (response.message) {
    throw new Error(response.message);
  }

  return response.data || {};
};

export const postVoucherCheck = async ({
  campaignId,
  chipInAmount,
  voucherCode
}: Voucher) => {
  const { data, message } = await api({
    endpoint: VOUCHER_CHECK_POST,
    body: {
      applicationId: campaignId,
      voucherCode,
      chipInAmount
    }
  });

  if (message) {
    throw new Error(message);
  }

  return data;
};
