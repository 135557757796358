export const server = {
  api: window.__RUNTIME_CONFIG__.REACT_APP_API_HOST,
  share: window.__RUNTIME_CONFIG__.REACT_APP_API_SHARE,
  aladan: window.__RUNTIME_CONFIG__.REACT_APP_API_ALADHAN,
  kemenag: window.__RUNTIME_CONFIG__.REACT_APP_API_KEMENAG,
  host: window.__RUNTIME_CONFIG__.REACT_APP_SERVER_HOST,
  portHost: window.__RUNTIME_CONFIG__.REACT_APP_SERVER_PORT
};

export const features = {
  /*
   * @feature ECO-3664
   * Re-assessment for every financing request
   */
  reAssessmentFeature:
    window.__RUNTIME_CONFIG__.FF_BENEF_ECO_REASSESSMENT === 'true'
};

export const provider = {
  google_id: window.__RUNTIME_CONFIG__.REACT_APP_GOOGLE_CLIENT_ID,
  google_secret: window.__RUNTIME_CONFIG__.REACT_APP_GOOGLE_CLIENT_SECRET
};

export const device = {
  is_mobile_tablet: '(max-device-width: 1024px)',
  is_desktop: '(min-device-width: 1025px)'
};
