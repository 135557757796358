import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AUTHENTICATION_TYPE } from 'commons/constants/authenticationType';
import { LOGIN } from 'commons/constants/routePath';
import { APPS_INFO, AUTH_USER, USER_VERIFY } from 'commons/constants/variable';
import { decryptionAES } from 'utils/encryption';
import {
  setLocalStorage,
  removeLocalStorage,
  getLocalStorage
} from 'utils/localStorage';
import { getRefreshToken } from 'queries/auth';
import { getCookie, removeCookie } from 'utils/cookies';
import { decryption } from 'utils/buffer';
import type { UserToken } from 'types/common';
import { authService } from 'service';

type AuthContextType = {
  user: UserToken;
  onSignIn: (user: UserToken, callback?: () => void) => void;
  onSignOut: () => void;
};

const defaultUserAuth: UserToken = {
  username: '',
  expiresIn: 0,
  refreshToken: '',
  token: '',
  userRoleTypeId: 0,
  userId: '',
  benefType: ''
};

const token = getCookie(AUTH_USER) ?? getLocalStorage(AUTH_USER);

const decryptDataInStorage = () => {
  if (token) {
    return getCookie(AUTH_USER) ? decryption(token) : decryptionAES(token);
  }

  return {};
};

const dataDecryption = decryptDataInStorage();

const AuthContext = React.createContext<AuthContextType>(null!);

const Authprovider: React.FC<PropsWithChildren> = ({ children = null }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const { FUNDER_RETAIL_WNA, FUNDER_BADAN_HUKUM, BENEFICIARY_NON_ECO } =
    AUTHENTICATION_TYPE;

  const [user, setUser] = useState<UserToken>({
    ...defaultUserAuth,
    ...dataDecryption
  });
  const refInterval = useRef<ReturnType<typeof setInterval>>();

  const onSignIn = (userAuth: UserToken) => {
    setUser((oldData) => {
      const userData = { ...oldData, ...userAuth };
      authService.setUser(userData);
      return userData;
    });
  };

  const onSignOut = useCallback(() => {
    setUser(defaultUserAuth);
    removeCookie(AUTH_USER);
    removeLocalStorage(AUTH_USER);
    removeLocalStorage('chipInAmount');
    setLocalStorage(APPS_INFO, true);
    navigate(LOGIN, { replace: true, state: { from: location } });
    removeLocalStorage(USER_VERIFY);
    queryClient.clear();
  }, [location, navigate, queryClient]);

  const { mutate } = useMutation(getRefreshToken, {
    onSuccess: (data) => {
      onSignIn({
        ...user /* keep data previous */,
        ...data
      });
    },
    onError: () => {
      onSignOut();
    }
  });

  useEffect(() => {
    if (
      user.username !== '' &&
      ![FUNDER_RETAIL_WNA, BENEFICIARY_NON_ECO].includes(user.userRoleTypeId)
    ) {
      refInterval.current = setInterval(() => {
        if (Date.now() > user.expiresIn) {
          mutate();
          clearInterval(refInterval.current);
        }
      }, 10000);
    }

    return () => {
      if (refInterval.current) clearInterval(refInterval.current);
    };
  }, [
    mutate,
    user.username,
    user.expiresIn,
    user.userRoleTypeId,
    FUNDER_BADAN_HUKUM,
    BENEFICIARY_NON_ECO,
    FUNDER_RETAIL_WNA
  ]);

  const value = useMemo(() => {
    return {
      user,
      onSignIn,
      onSignOut
    };
  }, [onSignOut, user]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default Authprovider;

export const useAuth = () => {
  return useContext(AuthContext);
};
