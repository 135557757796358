import { BaseHttpClient } from 'service/http-base/BaseHttpClient';
import {
  simulateCalculatorSchema,
  type SimulationUjroh,
  type SimulateParams
} from 'types/campaign';
import type { Response } from 'types/common';

export class CampaignService extends BaseHttpClient {
  getSimulateCalculator(
    params: SimulateParams
  ): Promise<Response<SimulationUjroh>> {
    return this.get(
      `/p2p/api/funder/investment/${params.applicationId}/simulate?amount=${params.chipInAmount}`,
      {
        responseSchema: simulateCalculatorSchema,
        bypassSanitize: true
      }
    );
  }
}
