import { Suspense, lazy } from 'react';
import { Route, Routes, useSearchParams } from 'react-router-dom';

import {
  AGRI_EXPIRED_LINK,
  AGRI_FINANCING_REQUEST,
  AGRI_FINANCING_REVIEW,
  AGRI_REVIEW_PROFILE_REGISTER,
  ECOFIN,
  ECOFIN_COMPLETEPROFILE,
  ECOFIN_COMPLETE_BUSINESS_DATA,
  ECOFIN_EMAIL_VERIFICATION,
  ECOFIN_FINANCING_LIST,
  ECOFIN_HOME,
  ECOFIN_INSTALLMENT_FINANCING_REQUEST,
  ECOFIN_NOTFOUND,
  ECOFIN_PROFILE,
  ECOFIN_PROFILE_VERIFICATION,
  ECOFIN_REGISTER,
  ECOFIN_REMINDER_REPAYMENT,
  ECOFIN_REVIEW_FINANCING_APPLICATION,
  ECOFIN_REVIEW_PROFILE_BUSINESS,
  ECOFIN_REVIEW_PROFILE_PERSONAL,
  ECOFIN_SUBMIT_PROOF_FUNDING,
  ERROR_500,
  FUNDER,
  FUNDER_ACCOUNT,
  FUNDER_ACCOUNT_PROFILE,
  FUNDER_ACCOUNT_VOUCHERS,
  FUNDER_CAMPAIGN,
  FUNDER_CAMPAIGN_DETAIL,
  FUNDER_DASHBOARD,
  FUNDER_DOCUMENT,
  FUNDER_DOCUMENT_FUNDING_APPROVAL,
  FUNDER_DOCUMENT_FUNDING_APPROVAL_TITLE,
  FUNDER_DOCUMENT_MEMBERSHIP,
  FUNDER_DOCUMENT_TAX_SLIP,
  FUNDER_DOCUMENT_UMBRELLA_AKAD,
  FUNDER_FAQ_DETAIL,
  FUNDER_FUNDING_SIMULATION,
  FUNDER_HELP,
  FUNDER_PAYMENT_VA,
  FUNDER_PORTFOLIO,
  FUNDER_PROFILE,
  FUNDER_RDF,
  FUNDER_SETIING_ACCOUNT_DELETE,
  FUNDER_SETIING_EMAIL,
  FUNDER_SETIING_LANGUAGE,
  FUNDER_SETIING_PASSWORD,
  FUNDER_SETIING_PHONE,
  FUNDER_SETTING,
  LOGIN,
  REGISTER,
  REGISTER_FUNDER,
  RESET_PASSWORD,
  RESET_PASSWORD_EXPIRED,
  RESET_PASSWORD_SET_EN,
  RESET_PASSWORD_SET_ID,
  FUNDER_DOCUMENT_NDA,
  FUNDER_DOCUMENT_PARTNERSHIP,
  FUNDER_DOCUMENT_FINANCING_COMMITMENT,
  FUNDER_DOCUMENT_AKAD,
  FUNDER_DOCUMENT_AKAD_DETAIL,
  REGISTER_CONFIRMATION_EMAIL,
  REGISTER_FUNDER_ACTIVATION,
  REGISTER_CREATE_PASSWORD,
  REGISTER_SUCCESS,
  REGISTER_ACTIVATION,
  FUNDER_COMPLETE_PROFILE_INSTITUTIONAL,
  FUNDER_ADD_BANK_ACCOUNT,
  FUNDER_GENERAL_RIPLAY,
  VERIFICATION_MFA,
  FUNDER_INFORMATION_FUNDER_UPDATE_DATA
} from 'commons/constants/routePath';
import { setCookie } from 'utils/cookies';

import WithSuspense from 'hoc/withSuspense';

import { AGRIBUSINESS_TYPE } from 'commons/constants/variable';
import Loading from 'components/funder/molecules/loading';
import features from 'config';
import { useAuth } from 'hoc/context/authprovider';
import { useRegistrationAuth } from 'hook/useRegistrationAuth';
import EcofinAuth from 'routes/routeAuth/ecofinAuth';
import RouteAuth from '../routeAuth';

const Account = lazy(() => {
  return import('pages/funder/account');
});
const AccountProfile = lazy(() => {
  return import('pages/funder/account/profile');
});
const AccountVouchers = lazy(() => {
  return import('pages/funder/account/voucher');
});
const Campaign = lazy(() => {
  return import('pages/funder/campaign');
});
const Dashboard = lazy(() => {
  return import('pages/funder/dashboard');
});
const CompleteProfileInstitutional = lazy(() => {
  return import('pages/funder/complete-profile/institutional');
});
const AddBankAccountInstitutionalFunder = lazy(() => {
  return import('pages/funder/add-bank-account');
});
const FundingSimulation = lazy(() => {
  return import('pages/funder/fundingSimulation');
});
const GeneralRiplay = lazy(() => {
  return import('pages/funder/riplay');
});
const InformationFunderUpdateData = lazy(() => {
  return import('pages/funder/information-funder-update-data');
});
const DetailCampaign = lazy(() => {
  return import('pages/funder/campaign/detail');
});
const Document = lazy(() => {
  return import('pages/funder/document');
});
const DocumentMembership = lazy(() => {
  return import('pages/funder/document/membership');
});
const DocumentUmbrellaAkad = lazy(() => {
  return import('pages/funder/document/umbrellaAkad');
});
const DocumentFundingApproval = lazy(() => {
  return import('pages/funder/document/fundingApproval');
});
const DocumentFundingApprovalShow = lazy(() => {
  return import('pages/funder/document/fundingApprovalShow');
});
const DocumentTaxSlip = lazy(() => {
  return import('pages/funder/document/tax');
});
const DocumentNDA = lazy(() => {
  return import('pages/funder/document/nonDisclosureAgreement');
});
const DocumentPartnershipAgreement = lazy(() => {
  return import('pages/funder/document/partnershipAgreement');
});
const DocumentFinancingCommitment = lazy(() => {
  return import('pages/funder/document/financingCommitment');
});
const DocumentManualAkad = lazy(() => {
  return import('pages/funder/document/manualAkad');
});
const DocumentManualAkadDetail = lazy(() => {
  return import('pages/funder/document/manualAkadDetail');
});
const Error404 = lazy(() => {
  return import('pages/funder/404');
});
const Error500 = lazy(() => {
  return import('pages/funder/500');
});
const FaqDetail = lazy(() => {
  return import('pages/funder/help/Faq/FaqDetail');
});
const FunderRDF = lazy(() => {
  return import('pages/funder/rdf');
});
const FunderPayment = lazy(() => {
  return import('pages/funder/payment');
});
const Help = lazy(() => {
  return import('pages/funder/help');
});
const Home = lazy(() => {
  return import('pages/funder/home');
});
const Layout = lazy(() => {
  return import('components/funder/template/layout');
});
const Login = lazy(() => {
  return import('pages/login');
});
const VerifcationMFA = lazy(() => {
  return import('pages/verification-mfa');
});
const Portfolio = lazy(() => {
  return import('pages/funder/portfolio');
});
const Profile = lazy(() => {
  return import('pages/funder/profile/index');
});
const Register = lazy(() => {
  return import('pages/register');
});
const RegisterFunder = lazy(() => {
  return import('pages/register/funder');
});
const RegisterConfirmationEmail = lazy(() => {
  return import('pages/register/confirmation-email');
});
const RegisterSuccess = lazy(() => {
  return import('pages/register/success');
});
const RegisterCreatePassword = lazy(() => {
  return import('pages/register/create-password');
});
const PublicCtlFunder = lazy(() => {
  return import('pages/publicCtl/funder');
});
const RegisterActivation = lazy(() => {
  return import('pages/register/activation');
});
const ResetPassword = lazy(() => {
  return import('pages/funder/resetPassword');
});
const ResetPasswordExpired = lazy(() => {
  return import('pages/funder/resetPasswordExpired');
});
const ResetPasswordSet = lazy(() => {
  return import('pages/funder/resetPasswordSet');
});
const Setting = lazy(() => {
  return import('pages/funder/setting');
});
const SettingAccountDelete = lazy(() => {
  return import('pages/funder/setting/accountDelete');
});
const SettingEmail = lazy(() => {
  return import('pages/funder/setting/email');
});
const SettingLanguange = lazy(() => {
  return import('pages/funder/setting/languange');
});
const SettingPassword = lazy(() => {
  return import('pages/funder/setting/password');
});
const SettingPhone = lazy(() => {
  return import('pages/funder/setting/phone');
});

/* BENEFICIARY ECOSYSTEM */
const LayoutBeneficiaryEcosystem = lazy(() => {
  return import('components/beneficiary-ecosystem/layout');
});

/* ECOFIN */
const Usp = lazy(() => {
  return import('pages/ecofin/usp');
});
const RegisterEcofin = lazy(() => {
  return import('pages/ecofin/register');
});
const ProfileEcofin = lazy(() => {
  return import('pages/ecofin/profile');
});
const CompleteProfile = lazy(() => {
  return import('pages/ecofin/complete-profile');
});
const HomeEcofin = lazy(() => {
  return import('pages/ecofin/home');
});
const Error404Ecofin = lazy(() => {
  return import('pages/ecofin/common/Error404');
});
const CompleteBusinessData = lazy(() => {
  return import('pages/ecofin/complete-business-data');
});
const ReviewProfilePersonal = lazy(() => {
  return import('pages/ecofin/profile-review/profile-review-personal');
});
const ReviewProfileBusiness = lazy(() => {
  return import('pages/ecofin/profile-review/profile-review-business');
});
const ProfileVerification = lazy(() => {
  return import('pages/ecofin/profile-verification');
});
const EmailVerification = lazy(() => {
  return import('pages/ecofin/email-verification');
});

const ReviewFinancingApplication = lazy(() => {
  return import('pages/ecofin/review-financing-application');
});
const FinancingList = lazy(() => {
  return import('pages/ecofin/financing-list');
});

const InstallmentFinancingRequest = lazy(() => {
  return import('pages/ecofin/installment-financing-request');
});
const CheckoutRepayment = lazy(() => {
  return import('pages/ecofin/checkout-repayment');
});
const SubmitProofFunding = lazy(() => {
  return import('pages/ecofin/submit-proof-funding');
});

/* AGRI */
const RegisterAgri = lazy(() => {
  return import('pages/agri/register');
});
const ReviewProfilePersonalAgri = lazy(() => {
  return import('pages/agri/profile-review');
});

const ExpiredLinkAgri = lazy(() => {
  return import('pages/agri/expired-link');
});

const HomePageAgri = lazy(() => {
  return import('pages/agri/home');
});

const ProfileAgri = lazy(() => {
  return import('pages/agri/profile');
});

const FinancingListAgri = lazy(() => {
  return import('pages/agri/financing-list');
});

const FinancingRequestAgri = lazy(() => {
  return import('pages/agri/financing-request');
});

const FinancingReviewAgri = lazy(() => {
  return import('pages/agri/financing-review');
});

const CheckoutRepaymentAgri = lazy(() => {
  return import('pages/agri/checkout-repayment');
});

const RoutesPage = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const { user } = useAuth();

  const dataRegisterAuth = useRegistrationAuth(token, {
    onSuccess: (data) => {
      if (data.body.partnerId) setCookie('partner_id', data.body.partnerId);
    }
  });

  const isAgriUserOnboarding =
    dataRegisterAuth.data?.body.partnerInfo?.partnerType ===
      AGRIBUSINESS_TYPE && features.signupAgriFeature;

  const isAgriUserLogin = user.benefType === AGRIBUSINESS_TYPE;

  return (
    <Routes>
      <Route index element={WithSuspense(Home)} />
      <Route path={LOGIN} element={WithSuspense(Login)} />
      <Route path={VERIFICATION_MFA} element={WithSuspense(VerifcationMFA)} />
      <Route path={REGISTER} element={WithSuspense(Register)} />
      <Route path={REGISTER_FUNDER} element={WithSuspense(RegisterFunder)} />
      <Route
        path={REGISTER_CONFIRMATION_EMAIL}
        element={WithSuspense(RegisterConfirmationEmail)}
      />
      <Route path={REGISTER_SUCCESS} element={WithSuspense(RegisterSuccess)} />

      <Route
        path={REGISTER_FUNDER_ACTIVATION}
        element={WithSuspense(PublicCtlFunder)}
      />
      <Route
        path={REGISTER_CREATE_PASSWORD}
        element={WithSuspense(RegisterCreatePassword)}
      />
      <Route
        path={REGISTER_ACTIVATION}
        element={WithSuspense(RegisterActivation)}
      />
      <Route path={RESET_PASSWORD} element={WithSuspense(ResetPassword)} />
      <Route
        path={RESET_PASSWORD_EXPIRED}
        element={WithSuspense(ResetPasswordExpired)}
      />
      {[RESET_PASSWORD_SET_ID, RESET_PASSWORD_SET_EN].map((path) => {
        return (
          <Route
            key={path}
            path={path}
            element={WithSuspense(ResetPasswordSet)}
          />
        );
      })}

      <Route
        path={FUNDER_COMPLETE_PROFILE_INSTITUTIONAL}
        element={WithSuspense(CompleteProfileInstitutional)}
      />
      <Route
        path={FUNDER_ADD_BANK_ACCOUNT}
        element={WithSuspense(AddBankAccountInstitutionalFunder)}
      />
      <Route
        path={FUNDER}
        element={<RouteAuth>{WithSuspense(Layout)}</RouteAuth>}
      >
        <Route path={FUNDER_ACCOUNT} element={WithSuspense(Account)}>
          <Route
            path={FUNDER_ACCOUNT_PROFILE}
            element={WithSuspense(AccountProfile)}
          />
          <Route
            path={FUNDER_ACCOUNT_VOUCHERS}
            element={WithSuspense(AccountVouchers)}
          />
        </Route>
        <Route path={FUNDER_DASHBOARD} element={WithSuspense(Dashboard)} />
        <Route
          path={FUNDER_FUNDING_SIMULATION}
          element={WithSuspense(FundingSimulation)}
        />
        <Route
          path={FUNDER_GENERAL_RIPLAY}
          element={WithSuspense(GeneralRiplay)}
        />
        <Route
          path={FUNDER_INFORMATION_FUNDER_UPDATE_DATA}
          element={WithSuspense(InformationFunderUpdateData)}
        />
        <Route path={FUNDER_DOCUMENT} element={WithSuspense(Document)}>
          <Route
            path={FUNDER_DOCUMENT_MEMBERSHIP}
            element={WithSuspense(DocumentMembership)}
          />
          <Route
            path={FUNDER_DOCUMENT_UMBRELLA_AKAD}
            element={WithSuspense(DocumentUmbrellaAkad)}
          />
          <Route
            path={FUNDER_DOCUMENT_FUNDING_APPROVAL}
            element={WithSuspense(DocumentFundingApproval)}
          />
          <Route
            path={FUNDER_DOCUMENT_FUNDING_APPROVAL_TITLE}
            element={WithSuspense(DocumentFundingApprovalShow)}
          />
          <Route
            path={FUNDER_DOCUMENT_TAX_SLIP}
            element={WithSuspense(DocumentTaxSlip)}
          />
          <Route
            path={FUNDER_DOCUMENT_NDA}
            element={WithSuspense(DocumentNDA)}
          />
          <Route
            path={FUNDER_DOCUMENT_PARTNERSHIP}
            element={WithSuspense(DocumentPartnershipAgreement)}
          />
          <Route
            path={FUNDER_DOCUMENT_FINANCING_COMMITMENT}
            element={WithSuspense(DocumentFinancingCommitment)}
          />
          <Route
            path={FUNDER_DOCUMENT_AKAD}
            element={WithSuspense(DocumentManualAkad)}
          />
          <Route
            path={FUNDER_DOCUMENT_AKAD_DETAIL}
            element={WithSuspense(DocumentManualAkadDetail)}
          />
        </Route>
        <Route path={FUNDER_CAMPAIGN} element={WithSuspense(Campaign)} />
        <Route
          path={FUNDER_CAMPAIGN_DETAIL}
          element={WithSuspense(DetailCampaign)}
        />
        <Route path={FUNDER_FAQ_DETAIL} element={WithSuspense(FaqDetail)} />
        <Route path={FUNDER_HELP} element={WithSuspense(Help)} />
        <Route path={FUNDER_PROFILE} element={WithSuspense(Profile)} />
        <Route path={FUNDER_PORTFOLIO} element={WithSuspense(Portfolio)} />
        <Route path={FUNDER_SETTING} element={WithSuspense(Setting)}>
          <Route
            path={FUNDER_SETIING_LANGUAGE}
            element={WithSuspense(SettingLanguange)}
          />
          <Route
            path={FUNDER_SETIING_PHONE}
            element={WithSuspense(SettingPhone)}
          />
          <Route
            path={FUNDER_SETIING_EMAIL}
            element={WithSuspense(SettingEmail)}
          />
          <Route
            path={FUNDER_SETIING_PASSWORD}
            element={WithSuspense(SettingPassword)}
          />
          <Route
            path={FUNDER_SETIING_ACCOUNT_DELETE}
            element={WithSuspense(SettingAccountDelete)}
          />
        </Route>
        <Route path={FUNDER_RDF} element={WithSuspense(FunderRDF)} />
      </Route>

      <Route path={FUNDER_PAYMENT_VA} element={WithSuspense(FunderPayment)} />

      {/* ECOFIN & AGRI */}
      <Route
        path={ECOFIN}
        element={
          <EcofinAuth dataRegistrationAuth={dataRegisterAuth}>
            {WithSuspense(LayoutBeneficiaryEcosystem)}
          </EcofinAuth>
        }
      >
        <Route
          path={AGRI_EXPIRED_LINK}
          element={WithSuspense(ExpiredLinkAgri)}
        />
        <Route
          path={AGRI_REVIEW_PROFILE_REGISTER}
          element={WithSuspense(ReviewProfilePersonalAgri)}
        />
        <Route path={ECOFIN} element={WithSuspense(Usp)} />
        <Route
          path={ECOFIN_REGISTER}
          element={
            <Suspense fallback={<Loading isLoading />}>
              <>
                {isAgriUserOnboarding ? (
                  <RegisterAgri dataRegistrationAuth={dataRegisterAuth} />
                ) : (
                  <RegisterEcofin />
                )}
              </>
            </Suspense>
          }
        />
        <Route
          path={ECOFIN_HOME}
          element={WithSuspense(isAgriUserLogin ? HomePageAgri : HomeEcofin)}
        />
        <Route
          path={ECOFIN_PROFILE}
          element={WithSuspense(isAgriUserLogin ? ProfileAgri : ProfileEcofin)}
        />
        <Route path={ECOFIN_NOTFOUND} element={WithSuspense(Error404Ecofin)} />
        <Route
          path={ECOFIN_COMPLETEPROFILE}
          element={WithSuspense(CompleteProfile)}
        />
        <Route
          path={ECOFIN_COMPLETE_BUSINESS_DATA}
          element={WithSuspense(CompleteBusinessData)}
        />
        <Route
          path={ECOFIN_REVIEW_PROFILE_PERSONAL}
          element={WithSuspense(
            isAgriUserLogin ? ReviewProfilePersonalAgri : ReviewProfilePersonal
          )}
        />
        <Route
          path={ECOFIN_PROFILE_VERIFICATION}
          element={WithSuspense(ProfileVerification)}
        />
        <Route
          path={ECOFIN_REVIEW_PROFILE_BUSINESS}
          element={WithSuspense(ReviewProfileBusiness)}
        />
        <Route
          path={ECOFIN_REVIEW_FINANCING_APPLICATION}
          element={WithSuspense(ReviewFinancingApplication)}
        />
        <Route
          path={ECOFIN_FINANCING_LIST}
          element={WithSuspense(
            isAgriUserLogin ? FinancingListAgri : FinancingList
          )}
        />
        <Route
          path={ECOFIN_EMAIL_VERIFICATION}
          element={WithSuspense(EmailVerification)}
        />
        <Route
          path={ECOFIN_INSTALLMENT_FINANCING_REQUEST}
          element={WithSuspense(InstallmentFinancingRequest)}
        />
        <Route
          path={AGRI_FINANCING_REQUEST}
          element={WithSuspense(FinancingRequestAgri)}
        />
        <Route
          path={AGRI_FINANCING_REVIEW}
          element={WithSuspense(FinancingReviewAgri)}
        />
        <Route
          path={ECOFIN_REMINDER_REPAYMENT}
          element={WithSuspense(
            isAgriUserLogin ? CheckoutRepaymentAgri : CheckoutRepayment
          )}
        />
        <Route
          path={ECOFIN_SUBMIT_PROOF_FUNDING}
          element={WithSuspense(SubmitProofFunding)}
        />
      </Route>

      <Route path={ERROR_500} element={WithSuspense(Error500)} />
      <Route path='*' element={WithSuspense(Error404)} />
    </Routes>
  );
};

export default RoutesPage;
