import {
  PASSWORD_POST,
  REFRESH_TOKEN_GET,
  REGISTRATION_AUTH,
  REQUEST_RESET_LINK_REGISTRATION_GET,
  RESET_PASSWORD_POST
} from 'commons/constants/endpoints';
import api from 'utils/api';
import { formatFormData } from 'utils/format';

export const getRefreshToken = async () => {
  const { data, message } = await api({
    endpoint: REFRESH_TOKEN_GET,
    isRefreshToken: true
  });

  if (message) {
    throw new Error(message);
  }

  return data;
};

export const getResetPassword = async ({ emailOrHandphone = '', key = '' }) => {
  const endpoint: any = [...RESET_PASSWORD_POST];
  let body: any;

  if (key) {
    endpoint[1] += `/${key}`;
  }
  if (emailOrHandphone) {
    const value: any = {
      username: emailOrHandphone
    };
    body = formatFormData(value);
  }

  const { data, message } = await api({
    endpoint,
    body,
    isNotContentType: !!emailOrHandphone,
    isFormData: !!emailOrHandphone,
    isAllResult: true
  });

  if (message) {
    throw new Error(message);
  }

  return data;
};

export const postSetPassword = async (value: {
  expiry: string;
  key: string;
  password: string;
  confirmPassword: string;
}) => {
  const { expiry, ...valueBody } = value;
  const { data, message } = await api({
    endpoint: PASSWORD_POST,
    body: formatFormData(valueBody),
    query: {
      expiry
    },
    isNotContentType: true,
    isFormData: true,
    isAllResult: true
  });

  if (message) {
    throw new Error(message);
  }

  return data;
};

export const registrationAuth = async ({ queryKey }: any) => {
  const { 1: token } = queryKey;
  const { data, message } = await api({
    endpoint: REGISTRATION_AUTH,
    params: {
      token
    },
    isAllResult: true
  });

  if (message) {
    throw new Error(message);
  }

  return data;
};

export const getRequestResendLink = async (token: string) => {
  const { data, message } = await api({
    endpoint: REQUEST_RESET_LINK_REGISTRATION_GET,
    params: {
      token
    }
  });

  if (message) {
    throw new Error(message);
  }

  return data;
};
