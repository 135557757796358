import { Buffer } from 'buffer';
import type { CustomFile } from 'components/funder/molecules/fileUpload';

export const encryption = (value: any) => {
  const encode = Buffer.from(JSON.stringify(value)).toString('base64');

  return encode;
};

export const decryption = (value?: any) => {
  const decode = JSON.parse(
    Buffer.from(value as string, 'base64').toString('utf-8')
  );

  return decode;
};

export const dataURIToBlob = (dataURI: string) => {
  const splitDataURI = dataURI.split(',');
  const byteString =
    splitDataURI[0].indexOf('base64') >= 0
      ? atob(splitDataURI[1])
      : decodeURI(splitDataURI[1]);
  const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i += 1)
    ia[i] = byteString.charCodeAt(i);

  return new Blob([ia], { type: mimeString });
};

export const dataBlobtoBase64 = (
  blob: string,
  callback: (base64: string) => void
) => {
  const img = document.createElement('img');
  img.src = blob;
  img.addEventListener('load', () => {
    const canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx = canvas.getContext('2d');
    ctx?.drawImage(img, 0, 0);
    const base64 = canvas.toDataURL('image/jpeg');
    callback(base64);
  });
};

export const blobUrlToFile = async (
  blobUrl: string,
  fileName: string,
  mimeType: string
): Promise<File> => {
  const response = await fetch(blobUrl);
  const blob = await response.blob();

  const file = new File([blob], fileName, { type: mimeType });

  return file;
};

export const parseBlobToCustomFiles = async (
  response: Blob,
  fileName: string
): Promise<CustomFile[]> => {
  const blobFile = window.URL.createObjectURL(response);
  const fetchResponse = await fetch(blobFile);
  const blob = await fetchResponse.blob();
  const mimeType = blob.type;

  const filesParse = await blobUrlToFile(blobFile, fileName, mimeType);
  return [filesParse].map((file) => {
    return Object.assign(file, {
      preview: URL.createObjectURL(file)
    });
  });
};
