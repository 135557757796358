import { useTranslation } from 'react-i18next';

import Close from 'assets/icon/close.svg';
import QRCode from 'assets/graphic/download-qr.svg';
import AppStore from 'assets/graphic/btn-appstore.svg';
import GooglePlay from 'assets/graphic/btn-googleplay.svg';

const DownloadDialog = () => {
  const { t } = useTranslation();
  return (
    <>
      <input type='checkbox' id='downloadDialog' className='modal-toggle' />
      <div className='modal'>
        <div className='modal-box relative max-w-2xl pt-8'>
          <label
            htmlFor='downloadDialog'
            className='absolute right-3 top-3 md:right-6 md:top-6 cursor-pointer'
          >
            <img src={Close} alt='close' />
          </label>
          <h3 className='text-headline-5 md:text-headline-4 text-center mb-6'>
            {t('profile.label290', 'Unduh ALAMI Sekarang!')}
          </h3>
          <p className='px-4 md:px-10 mb-6 text-center md:text-body-1 tracking-body-1'>
            {t(
              'profile.label291',
              'Dapatkan pengalaman mendanai lebih mudah, cepat, kapan saja dan dimana saja melalui ALAMI App'
            )}
          </p>
          <img className='mb-6 m-auto' src={QRCode} alt='Download QR' />
          <div className='flex flex-wrap items-center justify-center gap-2 md:gap-6'>
            <a
              href='https://app.adjust.com/8oenmw6?campaign=organic'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img src={AppStore} alt='App store' />
            </a>
            <a
              href='https://app.adjust.com/8oenmw6?campaign=organic'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img src={GooglePlay} alt='Google play' />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default DownloadDialog;
