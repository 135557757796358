import React from 'react';
import Lottie from 'react-lottie';
import classNames from 'classnames';

import SandClock from 'assets/lottie/loadingHourGlass.json';
import Modal from 'components/funder/atoms/modal';

type LoadingProps = {
  isLoading: boolean;
  message?: string;
  lottieStyle?: { width?: number };
  wrapperStyle?: string;
};

const Loading: React.FC<LoadingProps> = ({
  isLoading,
  message = '',
  lottieStyle = { width: 0 },
  wrapperStyle = ''
}) => {
  return (
    <Modal isShow={isLoading} isLoadingProgress>
      <div
        data-testid='loading-container'
        className={classNames(
          'modal-box w-fit relative px-6 pb-6 pt-7 transform-none duration-300 flex flex-col space-y-3.5 justify-center items-center rounded-2xl',
          {
            [wrapperStyle as string]: !!wrapperStyle
          }
        )}
      >
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: SandClock,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice'
            }
          }}
          isClickToPauseDisabled
          width={lottieStyle?.width || 62}
        />
        {message && (
          <span className='text-subtitle-2 font-normal tracking-subtitle-2'>
            {message}
          </span>
        )}
      </div>
    </Modal>
  );
};

export default Loading;
