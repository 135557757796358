import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';

import Snackbar from 'components/funder/molecules/snackbar';

export type SnackbarOn = {
  message: string;
  isSuccess?: boolean;
  duration?: number;
  callback?: () => void;
};

type SnackbarContextType = {
  onSnackbarShow: (options: SnackbarOn) => void;
};

const SnackbarContext = createContext<SnackbarContextType>(null!);

export type SnackbarState = {
  isOpen: boolean;
} & SnackbarOn;

const snackbarDefault = {
  isOpen: false,
  isSuccess: false,
  message: '',
  duration: 4000,
  callback: () => {}
};

const SnackbarProvider: React.FC<PropsWithChildren> = ({ children = null }) => {
  const [snackbar, setSnackbar] = useState<SnackbarState>(snackbarDefault);
  const closeTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    if (snackbar.isOpen) {
      closeTimeout.current = setTimeout(() => {
        if (snackbar.callback) {
          snackbar.callback();
        }
        setSnackbar((prev) => {
          return {
            ...snackbarDefault,
            ...prev,
            isOpen: false
          };
        });
      }, snackbar.duration);
    }

    return () => {
      if (closeTimeout.current) clearTimeout(closeTimeout.current);
    };
  }, [snackbar]);

  const onSnackbarShow = ({
    message,
    isSuccess,
    duration,
    callback
  }: SnackbarOn) => {
    setSnackbar({
      isOpen: true,
      isSuccess: !!isSuccess,
      message,
      duration: duration || snackbarDefault.duration,
      callback: callback || snackbarDefault.callback
    });
  };

  const onSnackbarClose = useCallback(() => {
    if (snackbar.callback) {
      snackbar.callback();
    }
    setSnackbar((prev) => {
      return {
        ...prev,
        isOpen: false
      };
    });
  }, [snackbar]);

  const value = useMemo(() => {
    return {
      onSnackbarShow
    };
  }, []);

  return (
    <SnackbarContext.Provider value={value}>
      {children}
      <Snackbar
        isOpen={snackbar.isOpen}
        isSuccess={snackbar.isSuccess as boolean}
        message={snackbar.message}
        handleClose={onSnackbarClose}
      />
    </SnackbarContext.Provider>
  );
};

export default SnackbarProvider;

export const useSnackbar = () => {
  return useContext(SnackbarContext);
};
