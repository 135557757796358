import UrlPattern from 'url-pattern';

import { server } from 'commons/constants/config';
import { Endpoint } from 'commons/constants/endpoints';
import { getLocalStorage } from 'utils/localStorage';
import { AUTH_USER, I18NEXT_LANG } from 'commons/constants/variable';
import { decryptionAES } from 'utils/encryption';
import { getCookie } from 'utils/cookies';
import { decryption } from 'utils/buffer';
import type { UserToken } from 'types/common';

const generatedUrl = (path: string, params: any, query: any) => {
  let endpoint = ``;
  if (path) {
    const pattern = new UrlPattern(path);
    endpoint = pattern.stringify(params);
  }
  if (query) {
    const queryParam = new URLSearchParams();
    Object.keys(query).forEach((key) => {
      queryParam.append(key, query[key]);
    });
    endpoint = `${endpoint}?${queryParam.toString()}`;
  }

  return endpoint;
};

type Options = {
  endpoint: Endpoint;
  params?: any;
  query?: any;
  body?: any;
  headers?: any;
  isDownload?: boolean;
  isNotContentType?: boolean;
  isFormData?: boolean;
  isRefreshToken?: boolean;
  isAllResult?: boolean;
  responseType?: any;
};

const api = async (options: Options): Promise<any> => {
  const {
    body,
    endpoint,
    headers = {},
    params = {},
    query,
    isDownload = false,
    isNotContentType = false,
    isRefreshToken,
    isFormData = false,
    isAllResult = false,
    responseType
  } = options;
  const [method, path, isShare] = endpoint;
  const baseUrl = generatedUrl(path, params, query);
  const lang = getLocalStorage(I18NEXT_LANG);
  const token = getCookie(AUTH_USER) ?? getLocalStorage(AUTH_USER);

  const dev = window.__RUNTIME_CONFIG__.NODE_ENV === 'development';

  const shareApi = dev ? `/share${baseUrl}` : `${server.share}${baseUrl}`;
  const proxyUrl = dev
    ? `/mobile${baseUrl}`
    : `${server.api}${baseUrl}`; /* for production need trial */
  const fetchApi = isShare ? shareApi : proxyUrl;

  let contentType = 'application/json';

  if (isFormData) {
    contentType = 'multipart/form-data';
  } else if (responseType === 'blob') {
    contentType = 'application/pdf';
  }

  const config: Record<string, any> = {
    method,
    credentials: 'same-origin',
    headers: {
      'Content-Type': contentType,
      'Accept-Language': lang,
      ...headers
    }
  };

  if (method !== 'get' && body) {
    if (isFormData) {
      config.body = body;
    } else {
      config.body = JSON.stringify(body);
    }
    if (isNotContentType) {
      delete config.headers['Content-Type'];
    }
  }

  if (isFormData) {
    config.headers = {};
  }

  if (token) {
    const decode: UserToken = getCookie(AUTH_USER)
      ? decryption(token)
      : decryptionAES(token);
    if (isRefreshToken) {
      config.headers.Authorization = `Bearer ${decode.refreshToken}`;
    } else {
      config.headers.Authorization = `Bearer ${decode.token}`;
    }
  }

  try {
    const response = await fetch(fetchApi, config);
    if (response.status === 502) {
      throw new Error(response.statusText);
    }
    let result;
    if (responseType === 'blob') {
      result = await response.blob();
      if (isDownload) {
        return { data: result };
      }
      const url = window.URL.createObjectURL(result);
      return { data: url };
    }

    result = await response.json();
    const gatewayError = lang
      ? 'Terjadi kesalahan pada server, coba lagi nanti'
      : 'Something went wrong, try again later';

    if (result?.statusCode >= 500 || result?.status >= 500) {
      throw new Error(gatewayError);
    }

    /*
      # statuscode 400 will passed cuz ex: /profile/phone/verify to sent error message
      # logic (response?.status === 200 && result) sometimes ex: api relami-code not have key status or statusCode
      # statusCode 429 is for too many request error in ecofin OTP
    */
    if (
      result?.statusCode === 400 ||
      result?.statusCode === 200 ||
      result?.status === 200 ||
      (response?.status === 200 && result) ||
      result?.statusCode === 429
    ) {
      let newResponse: any;

      newResponse = result.body || result;

      if (isAllResult) {
        /* handle from endpoint reset-password and set reset password */
        newResponse = result;
      }

      return { data: newResponse };
    }

    throw new Error(result?.statusMessage || result?.message);
  } catch (error: any) {
    return error;
  }
};

export default api;
