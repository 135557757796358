import {
  useQuery,
  type UseQueryOptions,
  type UseQueryResult
} from '@tanstack/react-query';
import { registrationAuth } from 'queries/auth';

export interface ResponseRegistrationAuth {
  body: Body;
  statusMessage: string;
  statusCode: number;
}

export interface Body {
  partnerInfo?: PartnerInfo;
  partnerId?: string;
  message: string;
}

export interface PartnerInfo {
  userStatus: string;
  partnerName: string;
  partnerType: string;
  userName: string;
}

export const useRegistrationAuth = (
  token: string | null,
  options?: UseQueryOptions<ResponseRegistrationAuth, Error>
): UseQueryResult<ResponseRegistrationAuth, Error> => {
  return useQuery<ResponseRegistrationAuth, Error>(
    ['registration-auth', token],
    registrationAuth,
    {
      ...options,
      enabled: !!token
    }
  );
};
