import React from 'react';
import Slider, { Settings } from 'react-slick';

type CarouselProps = {
  sliderSettings?: Settings;
  styleClass?: string;
} & React.PropsWithChildren;

const Carousel = React.forwardRef<Slider, CarouselProps>(
  ({ children, sliderSettings, styleClass = '' }, ref) => {
    const settings: Settings = {
      className: `max-w-full w-full eco-slider  ${styleClass}`,
      arrows: false,
      dots: false,
      draggable: false,
      swipeToSlide: false,
      swipe: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      ...sliderSettings
    };

    return (
      <Slider ref={ref} {...settings}>
        {children}
      </Slider>
    );
  }
);

Carousel.displayName = 'Carousel';
Carousel.defaultProps = {
  sliderSettings: {},
  styleClass: ''
};

export default Carousel;
