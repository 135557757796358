import React, { PropsWithChildren } from 'react';
import Authprovider from './authprovider';
import SnackbarProvider from './snackbarProvider';
import VoucherProvider from './voucherProvider';

const Provider = [Authprovider, SnackbarProvider, VoucherProvider];

const HOC: React.FC<PropsWithChildren> = ({ children = null }) => {
  return (
    <>
      {Provider.reduceRight(
        (AccumulatedComponents, CurrentComponent: React.FC<any>) => {
          return <CurrentComponent>{AccumulatedComponents}</CurrentComponent>;
        },
        children
      )}
    </>
  );
};

export default HOC;
