import { BaseHttpClient } from 'service/http-base/BaseHttpClient';
import type { Response } from 'types/common';
import type {
  WithdrawalAccount,
  WithdrawalList,
  StatusTransferType,
  WithdrawalAmountParam,
  AccountBalance
} from 'types/rdf';
import {
  accountBalanceSchema,
  statusTransferTypeSchema,
  withdrawalAccountSchema,
  withdrawalListSchema
} from 'types/rdf';

export class RdfService extends BaseHttpClient {
  getBankTransferChargeTypesByAmount(
    amount: number
  ): Promise<Response<StatusTransferType>> {
    return this.get(`/p2p/api/funder/rdl/transfer-type/bank-charge/${amount}`, {
      responseSchema: statusTransferTypeSchema
    });
  }

  getWithdrawalAccount(): Promise<Response<WithdrawalAccount>> {
    return this.get('/p2p/api/funder/rdl/withdraw-account', {
      responseSchema: withdrawalAccountSchema
    });
  }

  getWithdrawalList(): Promise<Response<WithdrawalList>> {
    return this.get(
      '/p2p/api/funder/rdl/withdrawal-list?status=50&order=requestDate',
      {
        responseSchema: withdrawalListSchema,
        bypassSanitize: true
      }
    );
  }

  postWithdrawalAmount(params: WithdrawalAmountParam): Promise<Response<any>> {
    return this.post('/p2p/api/funder/rdl/withdraw-amount', params);
  }

  getAccountBalance(): Promise<Response<AccountBalance>> {
    return this.get('/p2p/api/funder/rdl/account-balance', {
      responseSchema: accountBalanceSchema
    });
  }
}
