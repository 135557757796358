import { BaseHttpClient } from 'service/http-base/BaseHttpClient';
import type { Response } from 'types/common';
import type {
  CompanyDataResponse,
  CompanyDocumentResponse,
  CompanyManagementResponse,
  CompanyRepresentativeResponse,
  ParamCompanyData,
  ParamCompanyDocument,
  ParamCompanyManagement,
  ParamCompanyRepresentative,
  ParamMembershipCommitment,
  ProfileCompanyDataResponse,
  RequestDeleteReasonAccount,
  RequestOTPBankAccount,
  RequestOTPEmail,
  RequestOTPPassword,
  RequestOTPPhoneNumber,
  RequestUpdateProfile,
  ResponseAccountConfirmationDelete,
  ResponseDeleteAccountReasons,
  PeriodicReview,
  ResponsePreCheckAccountDelete,
  ResponseProfile,
  ResponseRequestOTP,
  SummaryCompanyDataResponse,
  InstitutionalContract
} from 'types/profile';
import {
  companyDataResponseSchema,
  companyDocumentResponseSchema,
  companyManagementResponseSchema,
  companyRepresentativeRespSchema,
  institutionalContractSchema,
  listResponseDeleteAccountReasonSchema,
  profileCompanyDataResponseSchema,
  responseAccountConfirmationDeleteSchema,
  responsePeriodicReviewSchema,
  responsePreCheckAccountDeleteSchema,
  responseProfileSchema,
  summaryCompanyDataResponseSchema
} from 'types/profile';

export class ProfileService extends BaseHttpClient {
  getCompanyRepresentative(): Promise<Response<CompanyRepresentativeResponse>> {
    return this.get('/p2p/api/funder/v3/profile/institutional/company-pic', {
      responseSchema: companyRepresentativeRespSchema
    });
  }

  postCompanyRepresentative(
    params: ParamCompanyRepresentative
  ): Promise<Response<unknown>> {
    return this.post(
      '/p2p/api/funder/v3/profile/institutional/company-pic',
      params
    );
  }

  postCompanyData(params: ParamCompanyData): Promise<Response<unknown>> {
    return this.post(
      '/p2p/api/funder/v3/profile/institutional/company',
      params
    );
  }

  getCompanyData(): Promise<Response<CompanyDataResponse>> {
    return this.get('/p2p/api/funder/v3/profile/institutional/company', {
      responseSchema: companyDataResponseSchema
    });
  }

  postCompanyDocument(
    params: ParamCompanyDocument
  ): Promise<Response<unknown>> {
    return this.post(
      '/p2p/api/funder/v3/profile/institutional/company-doc',
      params
    );
  }

  getCompanyDocument(): Promise<Response<CompanyDocumentResponse>> {
    return this.get('/p2p/api/funder/v3/profile/institutional/company-doc', {
      responseSchema: companyDocumentResponseSchema
    });
  }

  postCompanyManagment(
    params: ParamCompanyManagement
  ): Promise<Response<unknown>> {
    return this.post(
      '/p2p/api/funder/v3/profile/institutional/company-management',
      params
    );
  }

  getCompanyManagement(): Promise<Response<CompanyManagementResponse>> {
    return this.get(
      '/p2p/api/funder/v3/profile/institutional/company-management',
      {
        responseSchema: companyManagementResponseSchema
      }
    );
  }

  getCompanySummary(): Promise<Response<SummaryCompanyDataResponse>> {
    return this.get('/p2p/api/funder/v3/profile/institutional?validate=true', {
      responseSchema: summaryCompanyDataResponseSchema
    });
  }

  postMembershipAgreement(
    params: ParamMembershipCommitment
  ): Promise<Response<{ waitingDays: number }>> {
    return this.post(
      '/p2p/api/funder/v3/profile/institutional/membership-commitment',
      params
    );
  }

  getCompanyProfile(): Promise<Response<ProfileCompanyDataResponse>> {
    return this.get('/p2p/api/funder/v3/profile/institutional', {
      responseSchema: profileCompanyDataResponseSchema
    });
  }

  postVerifyPassword(
    params: RequestOTPPassword
  ): Promise<Response<ResponseRequestOTP>> {
    return this.post('/p2p/api/funder/profile/password/verify', params, {
      bypassSanitize: true
    });
  }

  getAccountConfirmationDelete(): Promise<
    Response<ResponseAccountConfirmationDelete>
  > {
    return this.get('/p2p/api/funder/account/delete/confirmation', {
      responseSchema: responseAccountConfirmationDeleteSchema
    });
  }

  getPreCheckAccountDelete(): Promise<Response<ResponsePreCheckAccountDelete>> {
    return this.get('/p2p/api/funder/account/delete/pre-check', {
      responseSchema: responsePreCheckAccountDeleteSchema
    });
  }

  postDeleteAccountReason(
    params: RequestDeleteReasonAccount
  ): Promise<Response<unknown>> {
    return this.post('/p2p/api/funder/account/delete/reason', params);
  }

  postVerifyDeleteAccount(params: {
    password: string;
  }): Promise<Response<ResponseRequestOTP>> {
    return this.post('/p2p/api/funder/account/delete/verify', params, {
      bypassSanitize: true
    });
  }

  getListDeleteReason(): Promise<Response<ResponseDeleteAccountReasons>> {
    return this.get('/p2p/api/funder/account-delete-reasons', {
      responseSchema: listResponseDeleteAccountReasonSchema
    });
  }

  postVerifyPhoneNumber(
    params: RequestOTPPhoneNumber
  ): Promise<Response<ResponseRequestOTP>> {
    return this.post('/p2p/api/funder/profile/phone/verify', params, {
      bypassSanitize: true
    });
  }

  postVerifyEmail(
    params: RequestOTPEmail
  ): Promise<Response<ResponseRequestOTP>> {
    return this.post('/p2p/api/funder/profile/email/verify', params, {
      bypassSanitize: true
    });
  }

  postVerifyBankAccount(
    params: RequestOTPBankAccount
  ): Promise<Response<ResponseRequestOTP>> {
    return this.post('/p2p/api/funder/v2/profile/bank-account/verify', params, {
      bypassSanitize: true
    });
  }

  postUpdateProfile(params: RequestUpdateProfile): Promise<Response<null>> {
    return this.post('/p2p/api/funder/v2/profile/update', params);
  }

  getProfileUser(): Promise<Response<ResponseProfile>> {
    return this.get('/p2p/api/funder/v2/profile/', {
      responseSchema: responseProfileSchema
    });
  }

  getPeriodicReview(): Promise<Response<PeriodicReview>> {
    return this.get('/p2p/api/funder/periodic-review', {
      responseSchema: responsePeriodicReviewSchema
    });
  }

  postConfirmPeriodicReviewInstitutional(): Promise<Response<null>> {
    return this.post(
      '/p2p/api/funder/v3/profile/institutional/confirm-periodic-review'
    );
  }

  getInstitutionalContract(): Promise<Response<InstitutionalContract>> {
    return this.get('/p2p/api/funder/v1/institution/profile', {
      responseSchema: institutionalContractSchema
    });
  }
}
