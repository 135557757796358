/* eslint-disable import/no-extraneous-dependencies */
import { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

import { ROUTER_BASE_URL } from 'commons/constants/routePath';
import { I18NEXT_LANG } from 'commons/constants/variable';
import DialogDownload from 'components/funder/molecules/modal/downloadDialog';
import DialogUnderConstruction from 'components/funder/molecules/modal/underconstruction';
import HocContext from 'hoc/context';
import RoutesPath from 'routes/routePath';
import { setLocalStorage } from 'utils/localStorage';

import 'utils/ensure-basename';

const development = window.__RUNTIME_CONFIG__.NODE_ENV === 'development';

const QueryClients = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
      cacheTime: 60 * 1000, // 60seconds
      staleTime: 0 * 10 * 1000 // 0seconds
    }
  }
});

dayjs.extend(duration);

const App = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    setLocalStorage(I18NEXT_LANG, i18n.language);
  }, [i18n.language]);

  return (
    <BrowserRouter basename={ROUTER_BASE_URL}>
      <QueryClientProvider client={QueryClients}>
        <HocContext>
          <RoutesPath />
          {development && <ReactQueryDevtools initialIsOpen={false} />}
          <DialogDownload />
          <DialogUnderConstruction />
        </HocContext>
      </QueryClientProvider>
    </BrowserRouter>
  );
};

export default App;
