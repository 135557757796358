import React from 'react';
import { ReactComponent as InfoBlue } from 'assets/icon/info-blue.svg';
import { ReactComponent as SuccessGreen } from 'assets/icon/check-success.svg';
import { ReactComponent as WarningTriangle } from 'assets/icon/warning-triangle.svg';
import { ReactComponent as Danger } from 'assets/icon/close-error.svg';
import { ReactComponent as LoadingTicker } from 'assets/icon/loading_ticker_icon.svg';
import { ReactComponent as InfoDanger } from 'assets/icon/info-danger.svg';
import { ReactComponent as InfoGray } from 'assets/icon/info-gray.svg';

import classNames from 'classnames';

type TickerType = {
  content: string;
  type:
    | 'warning'
    | 'info'
    | 'success'
    | 'danger'
    | 'loading'
    | 'info-danger'
    | 'info-secondary';
  showIcon?: boolean;
  className?: string;
  contentClass?: string;
  id?: string;
} & React.PropsWithChildren;

const Ticker = ({
  content,
  type,
  showIcon = false,
  className = '',
  contentClass = '',
  id = '',
  children
}: TickerType) => {
  const iconType = () => {
    switch (type) {
      case 'warning':
        return <WarningTriangle width={18} height={18} />;
      case 'danger':
        return <Danger width={18} height={18} />;
      case 'info':
        return <InfoBlue width={18} height={18} />;
      case 'success':
        return <SuccessGreen width={18} height={18} />;
      case 'loading':
        return <LoadingTicker width={18} height={18} />;
      case 'info-danger':
        return <InfoDanger width={18} height={18} />;
      case 'info-secondary':
        return <InfoGray width={20} height={20} />;
      default:
        return null;
    }
  };

  return (
    <div
      id={id}
      className={classNames(`border rounded-xl mx-4 mt-8 mb-6`, {
        'border-[#EE4266] bg-[#FEF6F7]':
          type === 'danger' || type === 'info-danger',
        'border-[#FFD23F] bg-[#FFFCF5]': type === 'warning',
        'border-[#F6F8FC] bg-[#FFFCF5]': type === 'loading',
        'bg-[#F3FBF7] border-[#0EAD69]': type === 'success',
        'border-none bg-[#F6F8FC]': type === 'info-secondary',
        'bg-[#E2F9FF] border-[#069EC6]':
          type !== 'danger' &&
          type !== 'info-danger' &&
          type !== 'warning' &&
          type !== 'loading' &&
          type !== 'info-secondary' &&
          type !== 'success',
        [`${className}`]: !!className,
        [`${contentClass}`]: !!contentClass
      })}
    >
      <div className='p-3 flex gap-3 items-start'>
        {showIcon ? (
          <div className='flex items-start pt-1'>{iconType()}</div>
        ) : null}
        <div
          className={classNames({ [`${contentClass}`]: !!contentClass })}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </div>
      {children}
    </div>
  );
};

export default Ticker;
