import React from 'react';
import classNames from 'classnames';

export type ButtonProps = {
  iconOnly?: boolean;
  size?: 'normal' | 'small';
  styleClass?: string;
  variant?: 'primary' | 'secondary' | 'tertiary' | 'danger' | 'warning';
  leadingIcon?: React.ReactElement;
  leadingIconStyle?: string;
  trailingIcon?: React.ReactElement;
  trailingIconStyle?: string;
  type?: 'button' | 'submit' | 'reset';
}; /* dangger and warning not ready setup */

const Button: React.FC<
  ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>
> = ({
  children = null,
  styleClass = '',
  iconOnly = false,
  size = 'normal',
  variant = 'primary',
  leadingIcon = null,
  leadingIconStyle = '',
  trailingIcon = null,
  trailingIconStyle = '',
  type = 'button',
  ...props
}) => {
  return (
    <button
      {...props}
      className={classNames(`btn text-body-2 rounded-full normal-case`, {
        'h-11 min-h-[2.75rem]': size !== 'small',
        'btn-sm': size === 'small',
        '!btn-circle': iconOnly,
        'bg-primary border-primary text-white hover:border-primary hover:bg-primary-variant1 active:border-primary-variant1 disabled:text-light-grey-white2 disabled:bg-gray-border disabled:border-0':
          variant === 'primary',
        'btn-outline border-primary text-primary hover:text-primary-variant2 hover:bg-primary-light hover:border-primary-variant2 active:text-primary active:bg-primary-light disabled:text-light-grey-white2 disabled:border-light-grey-white2 disabled:bg-transparent':
          variant === 'secondary',
        'btn-ghost text-primary hover:bg-transparent hover:text-primary-variant2 active:text-primary-variant1 disabled:text-light-grey-white2':
          variant === 'tertiary',
        'bg-error-primary text-white border-error-primary hover:border-error-primary hover:bg-error-primary active:border-error-primary disabled:text-light-grey-white2 disabled:bg-gray-border disabled:border-0':
          variant === 'danger',
        'bg-warning-primary text-white border-warning-primary hover:border-warning-primary hover:bg-warning-primary active:border-warning-primary disabled:text-light-grey-white2 disabled:bg-gray-border disabled:border-0':
          variant === 'warning',
        [`${styleClass}`]: true
      })}
      // eslint-disable-next-line react/button-has-type
      type={type}
    >
      {leadingIcon && (
        <span
          className={classNames('mr-1', {
            [`${leadingIconStyle}`]: !!leadingIconStyle
          })}
        >
          {leadingIcon}
        </span>
      )}
      {children}
      {trailingIcon && (
        <span
          className={classNames('ml-1', {
            [`${trailingIconStyle}`]: !!trailingIconStyle
          })}
        >
          {trailingIcon}
        </span>
      )}
    </button>
  );
};

export default Button;
