import { z } from 'zod';
import { DocumentType, formStatusSchema } from './common';

export const companyRepresentativeRespSchema = z.object({
  address: z.string().nullable(),
  birthDate: z.string().nullable(),
  cityId: z.string().nullable(),
  companyPoaDocId: z.string().nullable(),
  emailPic: z.string().nullable(),
  emergencyPhoneName: z.string().nullable(),
  emergencyPhoneNumber: z.string().nullable(),
  isCompanyPic: z.boolean(),
  birthPlace: z.string(),
  provinceId: z.string().nullable(),
  ktpDocId: z.string().nullable(),
  ktpIssuingCity: z.string().nullable(),
  ktpNumber: z.string().nullable(),
  name: z.string().nullable(),
  npwpDocId: z.string().nullable(),
  npwpNumber: z.string().nullable(),
  occupationPositionKey: z.string().nullable(),
  phonePic: z.string().nullable(),
  rt: z.string().nullable(),
  rw: z.string().nullable(),
  selfieDocId: z.string().nullable(),
  subdistrictId: z.string().nullable(),
  titleId: z.string().nullable(),
  villageId: z.string().nullable(),
  formStatus: formStatusSchema
});

export type CompanyRepresentativeResponse = z.infer<
  typeof companyRepresentativeRespSchema
>;

export type ParamCompanyRepresentative = {
  isCompanyPic: boolean;
  companyPoaDocId: string;
  ktpDocId: string;
  npwpDocId: string;
  selfieDocId: string;
  ktpNumber: string;
  npwpNumber: string;
  titleId: string;
  name: string;
  birthPlace: string;
  ktpIssuingCity: string;
  birthDate: string;
  villageId: string;
  address: string;
  rt: string;
  rw: string;
  occupationPositionKey: string;
  emailPic: string;
  phonePic: string;
  emergencyPhoneName: string;
  emergencyPhoneNumber: string;
};

export type ParamCompanyData = {
  corporateTypeId: string;
  corporateTypeOthers: string;
  companyTypeId: string;
  companyTypeOthers: string;
  companyName: string;
  establishmentPlace: string;
  establishmentDate: string | null;
  establishmentDeedNumber: string;
  establishmentDeedDate: string | null;
  establishmentDeedDocId: string;
  companyAddress: string;
  companyCityId: string;
  companyNpwpDocId: string;
  companyPhone: string | null;
  companyEmail: string | null;
  companyWebsite: string | null;
  companyDesc: string;
  companyNpwpNumber: string | null;
  companySubIndustryId: string;
  companySubIndustryOthers: string;
  annualIncomeId: string;
  sourceOfFundId: string;
};

export const companyDataResponseSchema = z.object({
  corporateTypeId: z.string().nullable(),
  corporateTypeOthers: z.string().nullable(),
  companyTypeId: z.string().nullable(),
  companyTypeOthers: z.string().nullable(),
  companyName: z.string().nullable(),
  establishmentPlace: z.string().nullable(),
  establishmentDate: z.string().nullable(),
  establishmentDeedNumber: z.string().nullable(),
  establishmentDeedDate: z.string().nullable(),
  establishmentDeedDocId: z.string().nullable(),
  companyNpwpDocId: z.string().nullable(),
  companyAddress: z.string().nullable(),
  companyProvinceId: z.string().nullable(),
  companyCityId: z.string().nullable(),
  companyEmail: z.string().nullable(),
  companyPhone: z.string().nullable(),
  companyWebsite: z.string().nullable(),
  companyDesc: z.string().nullable(),
  companyNpwpNumber: z.string().nullable(),
  companyIndustryId: z.string().nullable(),
  companySubIndustryId: z.string().nullable(),
  companySubIndustryOthers: z.string().nullable(),
  subdistrictId: z.string().nullable(),
  annualIncomeId: z.number().nullable(),
  sourceOfFundId: z.string().nullable(),
  formStatus: formStatusSchema
});

export type CompanyDataResponse = z.infer<typeof companyDataResponseSchema>;

export type ParamAmendmentDeed = {
  id: string;
  typeKey: string;
  docId: string;
  skMenkumhamDocId: string;
};

export type ParamCompanyDocument = {
  lastAmendmentDeedDate: string;
  amendmentDeeds: ParamAmendmentDeed[];
  hasNib: boolean;
  nibNumber?: string | null;
  nibDocId?: string | null;
  siupNumber?: string | null;
  siupDocId?: string | null;
  tdpNumber?: string | null;
  tdpDocId?: string | null;
  trxReportDocId?: string | null;
  ndaDocId?: string | null;
  pksDocId?: string | null;
  skbStartDate?: string | null;
  skbEndDate?: string | null;
  skbDocId?: string | null;
};

export const amendmentDeedResponseSchema = z.object({
  id: z.string().nullable(),
  typeKey: z.string().nullable(),
  docId: z.string(),
  skMenkumhamDocId: z.string()
});

export type AmendDeedResponse = z.infer<typeof amendmentDeedResponseSchema>;

export const companyDocumentDetailSchema = z.object({
  nibDocId: z.string().optional().nullable(),
  siupDocId: z.string().optional().nullable(),
  tdpDocId: z.string().optional().nullable(),
  trxReportDocId: z.string(),
  ndaDocId: z.string().optional().nullable(),
  pksDocId: z.string().optional().nullable(),
  skbDocId: z.string().optional().nullable()
});

export type CompanyDocument = z.infer<typeof companyDocumentDetailSchema>;

export const companyDocumentResponseSchema = z
  .object({
    lastAmendmentDeedDate: z.string(),
    amendmentDeeds: z.array(amendmentDeedResponseSchema),
    hasNib: z.boolean(),
    nibNumber: z.string().optional().nullable(),
    siupNumber: z.string().optional().nullable(),
    tdpNumber: z.string().optional().nullable(),
    skbStartDate: z.string().optional().nullable(),
    skbEndDate: z.string().optional().nullable(),
    formStatus: formStatusSchema
  })
  .merge(companyDocumentDetailSchema);

export type CompanyDocumentResponse = z.infer<
  typeof companyDocumentResponseSchema
>;

export type ParamCoManagement = {
  id?: string | null;
  name: string | null;
  citizenshipId: number;
  ktpNumber: string | null;
  ktpDocId: string | null;
  npwpNumber: string | null;
  npwpDocId: string | null;
  npwpOwnershipKey: string | null;
  passportNumber: string | null;
  passportDocId: string | null;
  countryId: string | null;
  occupationPositionId: string | null;
  occupationPositionOthers: string | null;
  email: string | null;
};

export type ParamShareholder = {
  id?: string | null;
  characterTypeId: string | null;
  citizenshipId: number;
  name: string | null;
  ktpNumber: string | null;
  ktpDocId: string | null;
  npwpNumber: string | null;
  npwpOwnershipKey: string | null;
  npwpDocId: string | null;
  passportNumber: string | null;
  passportDocId: string | null;
  countryId: string | null;
  ownershipPercentage: number;
};

export type ParamUbo = {
  id?: string | null;
  name: string | null;
  citizenshipId: number;
  ktpNumber: string | null;
  ktpDocId: string | null;
  passportNumber: string | null;
  passportDocId: string | null;
  rdlJobId: string | null;
  countryId: string;
  rdlJobOthers: string | null;
  sourceOfFundId: string | null;
  rdlMonthlyIncomeId: string | null;
  customerRelationKey: string;
  supportingDocId: string | null;
};

export type ParamCompanyManagement = {
  coManagements: ParamCoManagement[];
  shareholders: ParamShareholder[];
  ubo: ParamUbo | null;
};

const coManagementResponseSchema = z.object({
  id: z.string().optional(),
  name: z.string().nullable(),
  citizenshipId: z.string().nullable(),
  ktpNumber: z.string().nullable(),
  ktpDocId: z.string(),
  npwpNumber: z.string().nullable(),
  npwpDocId: z.string(),
  npwpOwnershipKey: z.string().nullable(),
  passportNumber: z.string().nullable(),
  passportDocId: z.string(),
  countryId: z.string().nullable(),
  occupationPositionId: z.string().nullable(),
  occupationPositionOthers: z.string().nullable(),
  email: z.string().nullable()
});

export type CoManagementResponse = z.infer<typeof coManagementResponseSchema>;

const shareholderResponseSchema = z.object({
  id: z.string().optional(),
  characterTypeId: z.string().nullable(),
  citizenshipId: z.string().nullable(),
  name: z.string().nullable(),
  ktpNumber: z.string().nullable(),
  ktpDocId: z.string(),
  npwpNumber: z.string().nullable(),
  npwpDocId: z.string(),
  npwpOwnershipKey: z.string().nullable(),
  passportNumber: z.string().nullable(),
  passportDocId: z.string(),
  countryId: z.string().nullable(),
  ownershipPercentage: z.number().nullable()
});

export type ShareholderResponse = z.infer<typeof shareholderResponseSchema>;

const uboResponseSchema = z.object({
  id: z.string().optional(),
  name: z.string().nullable(),
  citizenshipId: z.string().optional(),
  ktpNumber: z.string().nullable(),
  ktpDocId: z.string(),
  passportNumber: z.string().nullable(),
  passportDocId: z.string(),
  rdlJobId: z.string().nullable(),
  countryId: z.string().nullable(),
  rdlJobOthers: z.string().nullable(),
  sourceOfFundId: z.string().nullable(),
  rdlMonthlyIncomeId: z.string().nullable(),
  customerRelationKey: z.string().nullable(),
  supportingDocId: z.string()
});

export type UboResponse = z.infer<typeof uboResponseSchema>;

export const companyManagementResponseSchema = z.object({
  coManagements: z.array(coManagementResponseSchema),
  shareholders: z.array(shareholderResponseSchema),
  ubo: uboResponseSchema,
  formStatus: formStatusSchema
});

export type CompanyManagementResponse = z.infer<
  typeof companyManagementResponseSchema
>;

export const summaryCompanyDataResponseSchema = z.object({
  companyPic: companyRepresentativeRespSchema,
  company: companyDataResponseSchema,
  companyDocument: companyDocumentResponseSchema,
  companyManagement: companyManagementResponseSchema
});

export type SummaryCompanyDataResponse = z.infer<
  typeof summaryCompanyDataResponseSchema
>;

export type ParamMembershipCommitment = {
  agreedMemberCommitment: boolean;
};

export const companyPICProfileSchema = companyRepresentativeRespSchema.omit({
  formStatus: true
});
export const companyDataProfileSchema = companyDataResponseSchema.omit({
  formStatus: true
});
export const companyDocumentProfileSchema = companyDocumentResponseSchema.omit({
  formStatus: true
});
export const companyManagementProfileSchema =
  companyManagementResponseSchema.omit({
    formStatus: true
  });

export const profileCompanyDataResponseSchema = z.object({
  companyPic: companyPICProfileSchema,
  company: companyDataProfileSchema,
  companyDocument: companyDocumentProfileSchema,
  companyManagement: companyManagementProfileSchema
});

export type ProfileCompanyDataResponse = z.infer<
  typeof profileCompanyDataResponseSchema
>;

export type CompanyPICProfileResponse = z.infer<typeof companyPICProfileSchema>;

export type CompanyDataProfileResponse = z.infer<
  typeof companyDataProfileSchema
>;

export type CompanyDocumentProfileResponse = z.infer<
  typeof companyDocumentProfileSchema
>;

export type CompanyManagementProfileResponse = z.infer<
  typeof companyManagementProfileSchema
>;

export const amendmentDeedSchema = z.object({
  id: z.string().nullable(),
  typeKey: z
    .object({ id: z.string(), name: z.string(), key: z.string() })
    .nullable(),
  docId: z.string(),
  skMenkumhamDocId: z.string()
});

export type AmendmendDeed = z.infer<typeof amendmentDeedSchema>;

export type DisplaySummaryData = {
  id: string;
  label: string;
  value: string;
  type?: 'document' | 'radio' | '';
  docType?: DocumentType;
  docId?: string;
  fileName?: string;
};

export type CommonCoManagementFields = {
  ktpDocId: string;
  passportDocId: string;
  passportNumber: string;
  npwpDocId: string;
  ktpNumber: string;
  npwpNumber: string;
  npwpOwnership: string | null;
  country: string | null;
  occupationPositionOthers: string;
};

export type CommonUboFields = {
  ktpNumber: string;
  ktpDocId: string;
  passportNumber: string;
  passportDocId: string;
  rdlJobOthers: string;
  customerRelationId: string | null;
  supportingDocId: string;
  rdlJob: string;
  countryId: string;
  sourceOfFund: string;
  rdlMonthlyIncome: string;
};

export type CommonShareholderFields = {
  name: string;
  ktpNumber: string;
  ktpDocId: string;
  npwpNumber: string;
  npwpDocId: string;
  passportNumber: string;
  passportDocId: string;
  npwpOwnership: string | null;
  country: string | null;
};

export type ValidationStepCompleteProfile = {
  1: boolean;
  2: boolean;
  3: boolean;
  4: boolean;
};

export type CompleteProfileFormProps = {
  onHandlePrev: () => void;
  onHandleNext: () => void;
  currentStep: number | null;
  isShownErrorField: boolean | number | null;
};

export type OcrPhotoFormProps = {
  onShowAlertPermission: (value: boolean) => void;
  onHandleNext: () => void;
  onPrevious: () => void;
  onHandleUploadFile: (
    file: File | null,
    type: DocumentType,
    img: string
  ) => void;
  isLoadingUploadFile: boolean;
  isSuccessUploadFile: boolean;
  isCamReady: boolean;
  imgInitSrc: string;
  onHandleResetImg: (type: keyof OCRData) => void;
  hasErrorReject: boolean;
  onHandleCloseOCR: () => void;
  clearErrors: () => void;
  onLoadedData: () => void;
};

export type OCRData = {
  ktp: {
    imgSrc: string;
    id: string;
  };
  npwp: {
    imgSrc: string;
    id: string;
  };
  selfie: {
    imgSrc: string;
    id: string;
  };
};

export type TickerCheckBankAccount = {
  type:
    | 'warning'
    | 'info'
    | 'success'
    | 'danger'
    | 'loading'
    | 'info-danger'
    | 'loading-primary';
  isShow: boolean;
  isError: boolean;
  content: string;
  solid: boolean;
  showIcon: boolean;
};

export type ImageResultOcr = {
  id: number;
  src: string;
  alt: string;
  title: string;
  error: string;
};

export type RequestOTPPassword = {
  newPassword: string;
  confirmPassword: string;
  password: string;
};

export type ResponseRequestOTP = {
  requestId: string;
  nextRequestIn: number;
  sentTo: string;
  message: null | string;
};

export type PasswordField = {
  name: 'password' | 'newPassword' | 'confirmPassword';
  id: string;
  label: string;
  showPasswordKey: 'password' | 'newPassword' | 'confirmPassword';
  error?: { message?: string };
};

export type ShownPassword = {
  password: boolean;
  newPassword: boolean;
  confirmPassword: boolean;
};

export const responseAccountConfirmationDeleteSchema = z.object({
  email: z.string(),
  hasPassword: z.boolean(),
  phone: z.string()
});

export type ResponseAccountConfirmationDelete = z.infer<
  typeof responseAccountConfirmationDeleteSchema
>;

export const responsePreCheckAccountDeleteSchema = z.object({
  canDelete: z.boolean()
});

export type ResponsePreCheckAccountDelete = z.infer<
  typeof responsePreCheckAccountDeleteSchema
>;

export type RequestDeleteReasonAccount = {
  deleteAccountReason: number;
  deleteAccountReasonText: string;
};

export const responseDeleteAccountReasonSchema = z.object({
  createdDate: z.string(),
  enabled: z.boolean(),
  id: z.string(),
  modifiedDate: z.string().nullable(),
  priority: z.number(),
  reason: z.string()
});
export type ResponseDeleteAccountReason = z.infer<
  typeof responseDeleteAccountReasonSchema
>;

export const listResponseDeleteAccountReasonSchema = z.object({
  deleteAccountReasons: z.array(responseDeleteAccountReasonSchema)
});
export type ResponseDeleteAccountReasons = z.infer<
  typeof listResponseDeleteAccountReasonSchema
>;

export type RequestOTPPhoneNumber = {
  newPhoneNumber: string;
  password: string;
  phoneNumber: string;
};

export type RequestOTPEmail = {
  newEmail: string;
  password: string;
  email: string;
};

export type RequestOTPBankAccount = {
  bankId: string;
  ownerAccount: string;
  password: string;
};

export type RequestUpdateProfile = {
  maritalStatusId: string;
  educationLevelId: string;
  domicileAddress: string;
  domicileRt: string;
  domicileRw: string;
  domicileLocation4Id: string;
  sameAsKtp: boolean;

  monthlyIncomeId: string;
  sourceOfFundId: string;
  sourceOfFundName: string | null;
  fundingPurposeId: string;
  fundingPurposeName: string | null;

  occupationTypeId: number | null;
  occupationTypeName: string | null;

  occupationIndustryId: string | null;
  occupationPositionId: string | null;
  occupationIndustryName: string | null;
  companyName: string | null;
  companyAddress: string | null;
  companyPhone: string | null;
  workingExperienceId: string | null;

  emergencyPhoneName: string;
  emergencyPhoneRelationId: string;
  emergencyPhoneNumber: string;

  needUpdateData: boolean;
};

export const responseKTPUserSchema = z.object({
  ktpNumber: z.string(),
  name: z.string(),
  title: z.string(),
  birthPlace: z.string(),
  birthDate: z.string(),
  expiryDate: z.string(),
  city: z.string(),
  address: z.string(),
  rt: z.string(),
  rw: z.string(),
  location1Id: z.string(),
  location2Id: z.string(),
  location3Id: z.string(),
  location4Id: z.string()
});

export type ResponseKTPUser = z.infer<typeof responseKTPUserSchema>;

export const responseProfileSchema = z.object({
  motherMaidenName: z.string(),
  sex: z.string(),
  phone: z.string(),
  religionId: z.string(),
  maritalStatusId: z.string(),
  educationLevelId: z.string(),
  monthlyIncomeId: z.string(),
  sourceOfFundId: z.string().nullable(),
  sourceOfFundName: z.string().nullable(),
  fundingPurposeId: z.string().nullable(),
  fundingPurposeName: z.string().nullable(),
  occupationTypeId: z.number(),
  occupationTypeName: z.string().nullable(),
  occupationIndustryId: z.string(),
  occupationIndustryName: z.string(),
  occupationPositionId: z.string().nullable(),
  companyName: z.string(),
  companyAddress: z.string(),
  companyPhone: z.string(),
  workingExperienceId: z.string().nullable(),
  sameAsKtp: z.boolean().nullable(),
  domicileAddress: z.string(),
  domicileRt: z.string(),
  domicileRw: z.string(),
  domicileLocation1Id: z.string(),
  domicileLocation2Id: z.string(),
  domicileLocation3Id: z.string(),
  domicileLocation4Id: z.string(),
  emergencyPhoneName: z.string(),
  emergencyPhoneRelationId: z.string(),
  emergencyPhoneNumber: z.string(),
  subscribeInfo: z.string().nullable(),
  agreedToTerm: z.boolean(),
  rejectedFields: z.string().nullable(),
  ktp: z.object({
    ktpDocumentId: z.string(),
    ocr: z.string().nullable(),
    user: responseKTPUserSchema
  }),
  npwp: z.object({
    npwpDocumentId: z.string(),
    npwpOwnership: z.string(),
    ocr: z.string().nullable(),
    user: z.object({ npwpNumber: z.string() })
  }),
  selfie: z.object({ selfieDocumentId: z.string() })
});

export type ResponseProfile = z.infer<typeof responseProfileSchema>;

export const responsePeriodicReviewSchema = z.object({
  status: z.string(),
  diffPeriodicReviewDays: z.number().nullable()
});

export type PeriodicReview = z.infer<typeof responsePeriodicReviewSchema>;

export const institutionalContractSchema = z.object({
  usingAkadPayung: z.boolean().nullable()
});

export type InstitutionalContract = z.infer<typeof institutionalContractSchema>;
